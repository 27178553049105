import {SUPERVISORS_REDUX_SECTION_NAME} from "../../_constants";
import {SUPERVISOR_LIST_URL} from "../../constants";
import {Edit} from "../../../partials/Edit";
import React from "react";
import {supervisorsSlice} from "../../_redux";
import {Filter} from "../../../partials/Filter";
import {filterFields} from "./fields/filter";
import {fields} from "./fields/table";
import {SupervisorTable} from "./partials/Table";
import {FormattedMessage} from "react-intl";

export default {
    storeSection: SUPERVISORS_REDUX_SECTION_NAME,
    actions: supervisorsSlice.actions,
    base_url: SUPERVISOR_LIST_URL,
    listCmp: SupervisorTable,
    filterCmp: Filter,
    filterProps: {
        initValues: {
            search: '',
            school: '',
        },
        fields: filterFields
    },
    newCmp: Edit,
    editCmp: Edit,
    formProps: {
        initValues: {
            email: "",
            supervisor: {
                first_name: "",
                last_name: "",
                // fiscal_code: "",
            },
            school: ""
        },
        fields: fields
    },
    alias: {
        newTitle: <FormattedMessage
            id="PAGES.TITLE.NEW_SUPERVISOR"
        />,
        editTitle: <FormattedMessage
            id="PAGES.TITLE.EDIT_SUPERVISOR"
        />,
        listTitle: <FormattedMessage
            id="PAGES.TITLE.SUPERVISORS_LIST"
        />
    },
    filter_set: {
        sortField: "id",
    },
    cardActions: [],
    rules: {
        canCreate: []
    }

};
