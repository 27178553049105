import {CONTENT_CATEGORY_LIST_URL} from "../../constants";
import React from "react";
import {CONTENT_CATEGORIES_REDUX_SECTION_NAME} from "../../_constants";
import {TableList} from "../../../partials/Table";
import {Edit} from "../../../partials/Edit";
import {contentCategorySlice} from "../../_redux/contentCategory/contentCategorySlice";
import {fields} from "./fields";
import {columns} from "./columns";
import {FormattedMessage} from "react-intl";

export default {
    storeSection: CONTENT_CATEGORIES_REDUX_SECTION_NAME,
    actions: contentCategorySlice.actions,
    base_url: CONTENT_CATEGORY_LIST_URL,
    listCmp: () => {
        return <TableList columns={columns}/>;
    },
    filterCmp: () => <></>,
    newCmp: Edit,
    editCmp: Edit,
    formProps: {
        initValues: {
            title: '',
            description: '',
            slug: '',
            cover: null
        },
        fields
    },
    alias: {
        newTitle: <FormattedMessage
            id="PAGES.TITLE.NEW_CATEGORY"
        />,
        editTitle: <FormattedMessage
            id="PAGES.TITLE.EDIT_CATEGORY"
        />,
        listTitle: <FormattedMessage
            id="PAGES.TITLE.CATEGORIES_LIST"
        />
    },
    filter_set: {
        sortField: "id",
    },
    cardActions: [],
    rules: {
        canCreate: []
    }

};
