import {delayConfiguration, startConfiguration, subscribe, unsubscribe} from "pusher-redux";
import {GAME_EVENT, ROOM_SUBSCRIBED} from "./rooms/reducer";
import store from "./store";

delayConfiguration(store, "0ed7e6a5e78ae6e4a39d");


export const initPusher = (token) => {
    startConfiguration({
        cluster: "eu",
        auth: {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        },
        authEndpoint: `${process.env.REACT_APP_BASE_CHANNEL}/broadcasting/auth`
    });
}

export const getGameRoom = (gameRoom, event = 'pusher:subscription_succeeded', type = ROOM_SUBSCRIBED.type) => {
    return subscribe(`presence-game-room.${gameRoom}`, event, type);
}

export const getGameRoomEvents = (gameRoom, event = 'pusher:subscription_succeeded', type = GAME_EVENT.type) => {
    return subscribe(`presence-game-room.${gameRoom}-events`, event, type);
}

export const forgetGameRoom = gameRoom => {
    return unsubscribe(`presence-game-room.${gameRoom}`);
}
