import {Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {ProfilerListGroupItem} from "./ProfilerListGroupItem";
import {capitalize} from "lodash";
import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

export const GameResults = (
    {
        onCheck,
        evaluationResults = {
            overall: 100,
            recognition: 100,
            reaction: 100,
            empathy: 100,
        },
        hierarchy = []
    }
) => {

    const results = {
        recognition: {
            results: [
                "The child seems to able to name the emotions felt by the character by identifying with it and by assessing the situation and the non-verbal behavior. This type of response reveals an excellent knowledge of emotional vocabulary as well as ability to read and interpret non-verbals.",
                "The child appears to lack some ability to recognize the emotions the character is feeling. The responses chosen are often not appropriate to the context or to the reading of nonverbal language. This suggests a possible limited knowledge of the emotional spectrum or a lack of familiarity in reading expressions.",
                "The child seems to able to name the emotions felt by the character by identifying with it and by assessing the situation and the non-verbal behavior. This type of response reveals an excellent knowledge of emotional vocabulary as well as ability to read and interpret non-verbals.",
            ],
            titles: [
                "High Emotional Intelligence",
                "Emotional intelligence needs to be integrated",
                "Emotional intelligence needs to be developed",
            ]
        },
        reaction: {
            results: [
                "The child seems to have an appropriate reaction to the emotion that the character is feeling. They can detect the emotion and  respond in a congruent and constructive way. This allows the child to not be overwhelmed by the emotion and not engage in maladaptive behavioral reactions.",
                "These types of responses indicate that the behavioral reaction enacted is not always in accordance with the emotion felt. The child chooses the least convenient reaction. This may indicate a lack of awareness of other reaction possibilities having a more adaptive and constructive long-term outcome.",
                "This result shows how the behavioral reaction is not in line with the emotions experienced by the character in the various vignettes. The child is not aware of what behavioral possibilities may be available.  For this reason, their responses seem to be mostly denial or conflict, rather than resolution.",
            ],
            titles: [
                "High Emotional Intelligence",
                "Emotional intelligence needs to be integrated",
                "Emotional intelligence needs to be developed",
            ]
        },
        empathy: {
            results: [
                "The child who gives this kind of answer is capable of experiencing empathy. They can understand what the other is feeling and empathize. This emotional openness allows them to chose a behavioral reaction that creates a deeper level of emotional and cognitive interaction.",
                "The child seems to not always have the ability to evaluate and understand the emotions of others and implement behaviors that facilitate different types of interactions. This result could signal a lack of connection between the emotional and cognitive worlds that would allow to develop a more emotionally driven behavioral response.",
                "The child seems to lack the ability to empathize and perceive the emotional state of the other. As a result, they are not able to activate a behavior that allows them to interact positively with the other. Those who achieve this type of result may be unfamiliar with the world of emotions and have difficulty understanding what is happening to those around them.",
            ],
            titles: [
                "High Emotional Intelligence",
                "Emotional intelligence needs to be integrated",
                "Emotional intelligence needs to be developed",
            ]
        },
    };

    const getEvaluationResult = (label, ev) => {
        if (ev === 100) {
            return {
                title: <span className="text-success">{results?.[label]?.titles?.[0] ?? 'N/A'}</span>,
                body: results?.[label]?.results?.[0] ?? 'N/A',
                variant: "success",
                evaluation: ev ?? 0,
                label,
            }
        } else if (ev >= 50) {
            return {
                title: <span className="text-primary">{results?.[label]?.titles?.[1] ?? 'N/A'}</span>,
                body: results?.[label]?.results?.[1] ?? 'N/A',
                variant: "primary",
                evaluation: ev ?? 0,
                label,
            }
        }
        return {
            title: <span className="text-warning">{results?.[label]?.titles?.[2] ?? 'N/A'}</span>,
            body: results?.[label]?.results?.[2] ?? 'N/A',
            variant: "warning",
            evaluation: ev ?? 0,
            label,
        }
    };

    const evaluations = {
        overall: evaluationResults.overall,
        recognition: getEvaluationResult('recognition', evaluationResults.recognition),
        reaction: getEvaluationResult('reaction', evaluationResults.reaction),
        empathy: getEvaluationResult('empathy', evaluationResults.empathy),
    }


    return <Row>
        <Col md={6}>
            <ListGroup variant="flush">
                <ProfilerListGroupItem
                    hierarchy={[
                        ...hierarchy
                    ].map(el => ((typeof el === 'string' && <h4><strong>{el}</strong></h4>) || el))}
                    progress={evaluations.overall}
                    variant="success"
                />
                <ProfilerListGroupItem
                    hierarchy={[
                        <div style={{
                            display: 'flex',
                            marginBottom:10
                        }}>
                            <div style={{
                                width: 24,
                                marginRight: 10
                            }}>
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/icons/recognition.svg"
                                    )}
                                />
                            </div>
                            <div>Recognition</div>
                        </div>
                    ]}
                    progress={evaluations.recognition.evaluation}
                    variant={evaluations.recognition.variant}
                />
                <ProfilerListGroupItem
                    hierarchy={[
                        <div style={{
                            display: 'flex',
                            marginBottom:10
                        }}>
                            <div style={{
                                width: 24,
                                marginRight: 10
                            }}>
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/icons/reaction.svg"
                                    )}
                                />
                            </div>
                            <div>Reaction</div>
                        </div>

                    ]}
                    progress={evaluations.reaction.evaluation}
                    variant={evaluations.reaction.variant}
                />
                <ProfilerListGroupItem
                    hierarchy={[
                        <div style={{
                            display: 'flex',
                            marginBottom:10
                        }}>
                            <div style={{
                                width: 24,
                                marginRight: 10
                            }}>
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/icons/empathy.svg"
                                    )}
                                />
                            </div>
                            <div>Empathy</div>
                        </div>
                    ]}
                    progress={evaluations.empathy.evaluation}
                    variant={evaluations.empathy.variant}
                />
            </ListGroup>
        </Col>
        <Col md={6}>
            <ListGroup variant="flush">
                <ListGroupItem className={`profile-row text-success`} style={{
                    minHeight: 61
                }}/>
                <ListGroupItem action onClick={() => {
                    onCheck(evaluations.recognition);
                }} className={`profile-row text-${evaluations.recognition.variant}`}>
                    <div>
                        {capitalize(evaluations.recognition.label)}:
                    </div>
                    <div>
                        {
                            evaluations.recognition.title
                        }
                    </div>
                </ListGroupItem>
                <ListGroupItem action onClick={() => {
                    onCheck(evaluations.reaction);
                }} className={`profile-row text-${evaluations.reaction.variant}`}>
                    <div>
                        {capitalize(evaluations.reaction.label)}:
                    </div>
                    <div>
                        {
                            evaluations.reaction.title
                        }
                    </div>
                </ListGroupItem>
                <ListGroupItem action onClick={() => {
                    onCheck(evaluations.empathy);
                }} className={`profile-row text-${evaluations.empathy.variant}`}>
                    <div>
                        {capitalize(evaluations.empathy.label)}:
                    </div>
                    <div>
                        {
                            evaluations.empathy.title
                        }
                    </div>
                </ListGroupItem>
            </ListGroup>
        </Col>
    </Row>
}
