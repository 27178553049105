import {quickSlice} from "../../../../setup/redux/slice/quickSlice";
import {basedApiMock} from "../../../../../_the_law/_helpers/forApiMock";
import * as auth from "../../../Auth/_redux/authRedux";

export const visitSlice = quickSlice({
    name: "visits",
    endpoint: basedApiMock("visit", "visit"),
    onActionEvents: {
        onCreateResource({resource, dispatch}) {
            dispatch(auth.actions.login(resource.token, true));
        }
    }
});
