import React, {useState} from "react";
import {Container, Grid} from "@material-ui/core";
import {Form, Image} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {login} from "../_redux/authCrud";
import {forApiMock} from "../../../../_the_law/_helpers/forApiMock";
import {map} from "lodash";
import {useHistory} from "react-router";
import {makeStyles} from "@material-ui/core/styles";

const initialValues = {
    email: "",
    password: "",
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            width: '100%',
        }
    },
    content: {
        flex: '1 0 auto',
        alignSelf: 'center',
        width: '100%'
    },
    cover: {
        display: 'none',
        width: '100%',
        borderRadius: '3px 0 0 3px',
        [theme.breakpoints.up("sm")]: {
            display: 'unset',
        }
    },
    cover_xs: {
        display: 'none',
        width: '100%',
        borderRadius: '3px 3px 0 0',
        [theme.breakpoints.down("xs")]: {
            display: 'unset',
        }
    },
    controls: {
        display: 'flex',
        alignSelf: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    input: {
        borderRadius: 50,
        width: '100%',
        paddingRight: 10
    },
    button: {
        borderRadius: 50
    }
}));


const SelyLogin = (props) => {
    const classes = useStyles();

    const {intl, isReLogin = false} = props;
    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID"
            }, {
                name: 'email'
            }))
            .min(3, intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD"
            }, {
                min: 3
            }))
            .max(50, intl.formatMessage({
                id: "AUTH.VALIDATION.MAX_LENGTH_FIELD"
            }, {
                max: 50
            }))
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        password: Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            setTimeout(() => {
                login(values.email, values.password)
                    .then(({data: {token = null, accessToken = null}}) => {
                        disableLoading();
                        isReLogin ? props.re_login(forApiMock(accessToken, token)) : props.login(forApiMock(accessToken, token));
                    })
                    .catch((e) => {
                        disableLoading();
                        setSubmitting(false);
                        setStatus(
                            map(e?.response?.data?.errors, el => <p>{el[0]}</p>)
                        );
                    });
            }, 1000);
        },
    });

    const history = useHistory();

    return <form
        style={{
            position: "absolute",
            width: '100%',
            height: '100%',
            backgroundColor: "white",
            top: 0,
            left: 0
        }}
        onSubmit={formik.handleSubmit}
    >
        <Container >
            <Grid container justify={"center"} alignContent={"center"} alignItems={"center"} spacing={2}>
                <Grid item xs={12} className="text-center">
                    <Image style={{
                        maxWidth: 500,
                        width:'100%'
                    }} fluid src="/media/logos/selyup_logo_welcome_login.jpg" alt="logo"/>
                </Grid>
                <Grid item xs={'auto'}>
                    {formik.status ? (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={"column"} spacing={2} alignItems={"center"}>
                        <Grid item style={{
                            width: '100%',
                            maxWidth: 500
                        }}>
                            <Form.Control
                                placeholder="Email"
                                type="email"
                                name="email"
                                {...formik.getFieldProps("email")}
                                className={classes.input}
                                autoComplete="username"
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.email}</div>
                                </div>
                            ) : null}
                        </Grid>
                        <Grid item style={{
                            width: '100%',
                            maxWidth: 500
                        }}>
                            <Form.Control
                                placeholder="Password"
                                type="password"
                                name="password"
                                className={classes.input}
                                {...formik.getFieldProps("password")}
                                autoComplete="current-password"
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.password}</div>
                                </div>
                            ) : null}
                        </Grid>
                        <Grid item>
                            <button
                                type="submit"
                                disabled={formik.isSubmitting}
                                style={{
                                    borderRadius: 50
                                }}
                                className={`btn btn-primary font-weight-bold px-15 my-3 rounded-xl ${classes.button}`}
                            >
                                <span><FormattedMessage id="AUTH.GENERAL.SIGNIN_BUTTON"/></span>
                                {loading && <span className="ml-3 spinner spinner-white"/>}
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <button type="button" className="btn btn-link" onClick={() => {
                        history.push("/auth/registration")
                    }}>Register</button> | <button onClick={() => {
                    history.push("/auth/forgot-password")
                }} type="button" className="btn btn-link">Forgot your password?</button>
                </Grid>
            </Grid>
        </Container>
    </form>
}


export default injectIntl(connect(null, auth.actions)(SelyLogin));


