import {STUDENTS_REDUX_SECTION_NAME} from "../../_constants";
import {STUDENT_LIST_URL} from "../../constants";
import {Filter} from "../../../partials/Filter";
import {Edit} from "../../../partials/Edit";
import {studentsSlice} from "../../_redux";
import {filterFields} from "./fields/filter";
import {fields} from "./fields/table";
import {StudentTable} from "./partials/Table";
import React from "react";
import {FormattedMessage} from "react-intl";
import {editResourceUrl} from "../../../Helpers";
import {Profiler} from "./Profiler";

export const studentsPageConfigs = {
    storeSection: STUDENTS_REDUX_SECTION_NAME,
    actions: studentsSlice.actions,
    base_url: STUDENT_LIST_URL,
    listCmp: StudentTable,
    filterCmp: Filter,
    filterProps: {
        initValues: {
            search: '',
            school: '',
            genre: '',
        },
        fields: filterFields
    },
    newCmp: Edit,
    editCmp: Edit,
    formProps: {
        initValues: {
            name: "",
            student: {
                first_name: "",
                last_name: "",
                genre: "",
                birth_date: ""
            },
            school: ""
        },
        fields: fields
    },
    alias: {
        newTitle: <FormattedMessage
            id="PAGES.TITLE.NEW_STUDENTS"
        />,
        editTitle: <FormattedMessage
            id="PAGES.TITLE.EDIT_STUDENTS"
        />,
        listTitle: <FormattedMessage
            id="PAGES.TITLE.STUDENTS_LIST"
        />
    },
    filter_set: {
        sortField: "id",
    },
    cardActions: [],
    rules: {
        canCreate: []
    },
    additionalRoutes: [
        {
            path: `${editResourceUrl(STUDENT_LIST_URL)}/profiler`,
            component: Profiler,
        },
    ]

};
