import {MustHaveRules} from "../../../../../_the_law/_helpers/MustHaveRules";
import AsideMenuListItem from "./AsideMenuListItem";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";
import React from "react";

export const AsideMenuItem = ({rules = [], title, to, multiple = false, submenus = [], icon, type='svg'}) => {

    console.log({submenus})

    return <MustHaveRules rules={rules}>
        <AsideMenuListItem
            icon={type === 'svg' ? <SVG src={toAbsoluteUrl(icon)}/> : <img width={20} src={toAbsoluteUrl(icon)} alt={title}/>}
            title={title}
            to={to}
            multiple={multiple}
            submenus={submenus}
        />
    </MustHaveRules>;
};
