import {quickSlice, updateEntities} from "../../../../setup/redux/slice/quickSlice";
import {SCHOOLS_ENDPOINT, SCHOOLS_REDUX_SECTION_NAME} from "../../_constants";
import Axios from "axios";
import {callTypes} from "../../../../setup/redux/slice/resourcesSlice";

export const schoolSlice = quickSlice({
    name: SCHOOLS_REDUX_SECTION_NAME,
    endpoint: SCHOOLS_ENDPOINT,
    onSliceEvents: {
        additionalReducers: {
            statusChanged: (state, action) => {
                state.error = null;
                state.actionsLoading = false;
                updateEntities(state, action.payload.resource);
            },
        }
    },
    onActionEvents: {
        additionalActions: (requests, actions) => ({
            setActive: (id, active = true) => dispatch => {
                dispatch(actions.startCall({callType: callTypes.action}));
                requests.changeActivationStatus(id, active)
                    .then(response => {
                        const resource = response.data;
                        return dispatch(actions.statusChanged({resource}));
                    })
                    .catch(error => {
                        error.clientMessage = "Can't activate resource";
                        return dispatch(actions.catchError({error, callType: callTypes.action}));
                    });
            }
        })
    },
    crudUrls: {
        additional_crud: {
            changeActivationStatus: (base_url) => (id, active) => {
                return Axios.post(`${base_url}/${id}/active`, {active})
            }
        }
    }
});
