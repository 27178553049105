import {Col, Row} from "react-bootstrap";
import {Field, useField} from "formik";
import {FormattedMessage} from "react-intl";
import {Input, Select} from "../../../../../_metronic/_partials/controls";
import React from "react";
import {AsOption} from "../../../partials/FormattedMessages";

const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
]

export const fields = <Row className="inputs-rows mt-5">
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SCHOOL.NAME"
            />}
            component={Input}
            name="name"
            key="name"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SCHOOL.REFERER.FIRST_NAME"
            />}
            component={Input}
            name="referer.first_name"
            key="referer.first_name"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SCHOOL.REFERER.LAST_NAME"
            />}
            component={Input}
            name="referer.last_name"
            key="referer.last_name"
        />
    </Col>
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SCHOOL.EMAIL"
            />}
            component={Input}
            name="email"
            key="email"
        />
    </Col>
    <Col xs={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SCHOOL.NEW_PASSWORD"
            />}
            component={Input}
            type="password"
            name="new_password"
            key="new_password"
        />
    </Col>
    <Col xs={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SCHOOL.CONFIRM_PASSWORD"
            />}
            component={Input}
            type="password"
            name="new_password_confirmation"
            key="new_password_confirmation"
        />
    </Col>
    {/*<Col xs={12}>*/}
    {/*    <Field*/}
    {/*        label={<FormattedMessage*/}
    {/*            id="APPLICATION.SCHOOL.PEC"*/}
    {/*        />}*/}
    {/*        component={Input}*/}
    {/*        name="info.pec_mail"*/}
    {/*        key="info.pec_mail"*/}
    {/*    />*/}
    {/*</Col>*/}
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SCHOOL.ADDRESS"
            />}
            component={Input}
            name="info.address"
            key="info.address"
        />
    </Col>
    <Col md={6}>
        <Field
            name="info.state"
            key="info.state"
        >
            {
                ({field, form}) => {
                    return <Select
                        withFeedbackLabel={false}
                        label="State"
                        value={field.value ?? ''}
                                   onChange={e => {
                                       form.setFieldValue(field.name, e.currentTarget.value);
                                   }}>
                        {<AsOption>
                            <FormattedMessage
                                id="APPLICATION.SUPERVISOR.SELECT_SCHOOL"
                            />
                        </AsOption>}
                        {
                            states.map(el => <option value={el}>{el}</option>)
                        }
                    </Select>;
                }
            }
        </Field>
    </Col>
    {/*<Col md={6}>*/}
    {/*    <Field*/}
    {/*        label={<FormattedMessage*/}
    {/*            id="APPLICATION.SCHOOL.DISTRICT"*/}
    {/*        />}*/}
    {/*        component={Input}*/}
    {/*        name="info.district"*/}
    {/*        key="info.district"*/}
    {/*    />*/}
    {/*</Col>*/}
</Row>
