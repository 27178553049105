import {basedApiMock} from "../../../../_the_law/_helpers/forApiMock";

export const SCHOOLS_ENDPOINT = basedApiMock("school", "school");
export const SCHOOLS_REDUX_SECTION_NAME = "schools";

export const SUPERVISORS_ENDPOINT = basedApiMock("supervisor", "supervisor");
export const SUPERVISORS_REDUX_SECTION_NAME = "supervisors";

export const STUDENTS_ENDPOINT = basedApiMock("student", "student");
export const STUDENTS_REDUX_SECTION_NAME = "students";

export const GROUPS_ENDPOINT = basedApiMock("student_group", "student_group");
export const GROUPS_REDUX_SECTION_NAME = "groups";

export const CONTENT_CATEGORIES_ENDPOINT = basedApiMock("content_category", "content_category");
export const CONTENT_CATEGORIES_REDUX_SECTION_NAME = "content_categories";

export const CONTENT_POSTS_ENDPOINT = basedApiMock("content_post", "content_post");
export const CONTENT_POSTS_REDUX_SECTION_NAME = "content_posts";

export const GAME_ROOMS_ENDPOINT = basedApiMock("game_room", "game_room");
export const GAME_ROOMS_REDUX_SECTION_NAME = "game_rooms";
