import {quickSlice} from "../../../../setup/redux/slice/quickSlice";
import {STUDENTS_ENDPOINT, STUDENTS_REDUX_SECTION_NAME} from "../../_constants";

export const studentsSlice = quickSlice({
    name: STUDENTS_REDUX_SECTION_NAME,
    endpoint: STUDENTS_ENDPOINT,
    onSliceEvents: {
        additionalState: {
            meta: {
                schools: [],
                supervisors: []
            }
        },
        onResourcesFetched(state, action) {
            state.meta.schools = action.payload?.meta?.schools ?? [];
            state.meta.supervisors = action.payload?.meta?.supervisors ?? [];
        }
    }
})
