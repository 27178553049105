import React, {useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import {Card, Container, Form} from "react-bootstrap";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import CardContent from '@material-ui/core/CardContent';
import {Grid} from "@material-ui/core";
import {useHistory} from "react-router";
import * as Yup from "yup";
import {useFormik} from "formik";
import {register} from "../_redux/authCrud";
import {map} from "lodash";

const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
]

const initialValues = {
    name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    state: '',
    address: ''
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            width: '100%',
        }
    },
    content: {
        flex: '1 0 auto',
        alignSelf: 'center',
        width: '100%'
    },
    cover: {
        display: 'none',
        width: '100%',
        borderRadius: '3px 0 0 3px',
        [theme.breakpoints.up("sm")]: {
            display: 'unset',
        }
    },
    cover_xs: {
        display: 'none',
        width: '100%',
        borderRadius: '3px 3px 0 0',
        [theme.breakpoints.down("xs")]: {
            display: 'unset',
        }
    },
    controls: {
        display: 'flex',
        alignSelf: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    input: {
        borderRadius: 50,
        width: '100%',
        paddingRight: 10
    },
    button: {
        borderRadius: 50
    }
}));

const MediaControlCard = injectIntl(connect(null, auth.actions)((props) => {
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    const signup_success_text = <h3>
        Your registration request has been taken over.
        <br/>
        You will receive an email as soon as your account is enabled.
    </h3>

    const {intl} = props;
    const [loading, setLoading] = useState(false);
    const RegistrationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(80, "Maximum 80 symbols")
            .required(
                "Field required"
            ),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                "Field required"
            ),
        first_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                "Field required"
            ),
        last_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                "Field required"
            ),
        password: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(30, "Maximum 50 symbols")
            .required(
                "Field required"
            ),
        password_confirmation: Yup.string()
            .required(
                "Field required"
            )
            .when("password", {
                is: (val) => (val && val.length > 0),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
        state: Yup.string()
            .required(
                "Field required"
            ),
        address: Yup.string()
            .required(
                "Field required"
            ),

    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const [signupSuccess, setSignupSuccess] = useState(false)

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            register(values)
                .then(({data: {token = null, accessToken = null}}) => {
                    disableLoading();
                    setStatus(null);
                    setSignupSuccess(true)
                })
                .catch(reason => {

                    let errors = [
                        <div>Registration error</div>
                    ];

                    if (reason?.response?.data?.errors) {
                        map(reason?.response?.data?.errors, el => errors.push(<div>{el[0]}</div>))
                    }

                    setSubmitting(false);
                    setStatus(errors);
                    disableLoading();
                });
        },
    });

    return (
        <Card className="my-4">
            <Form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
            >
                <Grid alignItems={"center"} container className={classes.root}>
                    <Grid item md={6} xs={12} className="text-center">
                        <img
                            style={{
                                backgroundSize: 'contain',
                                backgroundColor: '#2a82cc',
                                maxWidth: 500,
                            }}
                            className={classes.cover}
                            src="/media/logos/register.jpg"
                            alt={`Welcome to ${process.env.REACT_APP_NAME}`}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className={classes.details}>
                            <img
                                style={{
                                    backgroundSize: 'contain',
                                    backgroundColor: '#2a82cc'
                                }}
                                className={classes.cover_xs}
                                src="/media/logos/register.jpg"
                                title={`Welcome to ${process.env.REACT_APP_NAME}`}
                            />
                            <CardContent className={classes.content}>
                                {
                                    (signupSuccess &&
                                        <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                                            <div className="alert-text font-weight-bold">{signup_success_text}</div>
                                        </div>
                                    ) || <Container className="h-100">
                                        {formik.status && (
                                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                                <div className="alert-text font-weight-bold">{formik.status}</div>
                                            </div>
                                        )}
                                        <Grid container spacing={2} className="h-100" direction={"row"} alignItems={"center"} alignContent={"center"} justify={"center"}>
                                            <Grid item xs={12}>
                                                <Form.Control
                                                    className={classes.input}
                                                    type="text"
                                                    placeholder="Organization's name"
                                                    name="name"
                                                    {...formik.getFieldProps("name")}
                                                />
                                                {formik.touched["name"] && formik.errors["name"] ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{formik.errors["name"]}</div>
                                                    </div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Form.Control
                                                    className={classes.input}
                                                    type="text"
                                                    placeholder="First name"
                                                    name="first_name"
                                                    {...formik.getFieldProps("first_name")}
                                                />
                                                {formik.touched["first_name"] && formik.errors["first_name"] ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{formik.errors["first_name"]}</div>
                                                    </div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Form.Control
                                                    className={classes.input}
                                                    type="text"
                                                    placeholder="Last name"
                                                    name="last_name"
                                                    {...formik.getFieldProps("last_name")}
                                                />
                                                {formik.touched["last_name"] && formik.errors["last_name"] ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{formik.errors["last_name"]}</div>
                                                    </div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Form.Control
                                                    {...formik.getFieldProps("address")}
                                                    className={classes.input}
                                                    type="text"
                                                    placeholder="Address"
                                                    name="address"
                                                />
                                                {formik.touched["address"] && formik.errors["address"] ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{formik.errors["address"]}</div>
                                                    </div>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Form.Control
                                                    as="select"
                                                    cutom
                                                    className={classes.input}
                                                    placeholder="Select state"
                                                    name="state"
                                                    {...formik.getFieldProps("state")}>
                                                    >
                                                    <option value="">Select state</option>
                                                    {
                                                        states.map(el => <option value={el}>{el}</option>)
                                                    }
                                                </Form.Control>
                                                {formik.touched["state"] && formik.errors["state"] ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{formik.errors["state"]}</div>
                                                    </div>
                                                ) : null}

                                            </Grid>
                                            {/*<Grid item xs={12} md={6}>*/}
                                            {/*    <Form.Control*/}
                                            {/*        {...formik.getFieldProps("district")}*/}
                                            {/*        className={classes.input}*/}
                                            {/*        type="text"*/}
                                            {/*        placeholder="District"*/}
                                            {/*        name="district"*/}
                                            {/*    />*/}
                                            {/*    {formik.touched["district"] && formik.errors["district"] ? (*/}
                                            {/*        <div className="fv-plugins-message-container">*/}
                                            {/*            <div className="fv-help-block">{formik.errors["district"]}</div>*/}
                                            {/*        </div>*/}
                                            {/*    ) : null}*/}

                                            {/*</Grid>*/}

                                            <Grid item xs={12}>
                                                <Form.Control
                                                    {...formik.getFieldProps("email")}
                                                    className={classes.input}
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email address"
                                                    autoComplete="new-email"
                                                />
                                                {formik.touched["email"] && formik.errors["email"] ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{formik.errors["email"]}</div>
                                                    </div>
                                                ) : null}

                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Form.Control
                                                    {...formik.getFieldProps("password")}
                                                    className={classes.input}
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    autoComplete="new-password"
                                                />
                                                {formik.touched["password"] && formik.errors["password"] ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{formik.errors["password"]}</div>
                                                    </div>
                                                ) : null}

                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Form.Control
                                                    {...formik.getFieldProps("password_confirmation")}
                                                    className={classes.input}
                                                    type="password"
                                                    name="password_confirmation"
                                                    autoComplete="new-password"
                                                    placeholder="Confirm password"
                                                />
                                                {formik.touched["password_confirmation"] && formik.errors["password_confirmation"] ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{formik.errors["password_confirmation"]}</div>
                                                    </div>
                                                ) : null}

                                            </Grid>
                                        </Grid>
                                    </Container>
                                }
                            </CardContent>
                            {!signupSuccess && <div className={classes.controls}>
                                <button
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    className={`btn btn-primary font-weight-bold px-15 m-3 rounded-xl ${classes.button}`}
                                >
                                    <span><FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON"/></span>
                                </button>
                                <button
                                    disabled={formik.isSubmitting}
                                    onClick={() => history.push("/auth/login")}
                                    className={`btn btn-primary font-weight-bold px-15 m-3 rounded-xl ${classes.button}`}
                                >
                                    <span>Cancel</span>
                                </button>
                            </div>}
                            {!signupSuccess && <div className={classes.controls}>
                                <button
                                    disabled={formik.isSubmitting}
                                    onClick={() => history.push("/auth/login")} className="btn btn-link my-3">Already have an account?
                                </button>
                            </div>}
                        </div>
                    </Grid>
                </Grid>
            </Form>
        </Card>
    );
}));

const SelyRegistration = (props) => {

    return <Container className="h-100">
        <Grid className="h-100" container alignItems={"center"} justify={"center"}>
            <Grid item xs={12}>
                <MediaControlCard/>
            </Grid>
        </Grid>
    </Container>
}

export default SelyRegistration;
