import {InlineLogin} from "./InlineLogin";
import React, {Suspense, useMemo} from "react";
import {ContentRoute, LayoutSplashScreen} from "../../../_metronic/layout";
import {Redirect, Switch} from "react-router-dom";
import {RecoilRoot} from "recoil/dist";
import {MustHaveRules} from "../../../_the_law/_helpers/MustHaveRules";

export const ModuleContainer = ({moduleDefinition}) => {

    return useMemo(() =>
        <RecoilRoot>
            <InlineLogin/>
            <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                    <Redirect
                        exact
                        from={moduleDefinition.redirect.from}
                        to={moduleDefinition.redirect.to}
                    />
                    {
                        moduleDefinition.routes.map(
                            (props, i) => {

                                const {path, component, rules = []} = props;

                                return <MustHaveRules path={path} rules={rules}>
                                    <ContentRoute
                                        key={i}
                                        component={component}
                                        path={path}
                                    />
                                </MustHaveRules>;
                            }
                        )
                    }
                </Switch>
            </Suspense>
        </RecoilRoot>, [moduleDefinition]);
}
