/* eslint-disable no-restricted-imports */
import React, {useEffect, useMemo} from "react";
import {Modal} from "react-bootstrap";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {useUIContext} from "../UIContext";
import {FormattedMessage} from "react-intl";

export function SingleDeleteDialog({id, show, onHide}) {


    // Products UI Context
    const {actions, ...context} = useUIContext();
    const uiProps = useMemo(() => {
        return {
            setIds: context.setIds,
            queryParams: context.queryParams,
        };
    }, [context]);

    // Products Redux state
    const dispatch = useDispatch();
    const {isLoading} = useSelector(
        (state) => ({isLoading: state[context.storeSection].actionsLoading}),
        shallowEqual
    );

    // if !id we should close modal
    // useEffect(() => {
    //     if (!id) {
    //         onHide();
    //     }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [id]);

    // looking for loading/dispatch
    useEffect(() => {
    }, [isLoading, dispatch]);

    const deleteProduct = () => {
        // server request for deleting product by id
        dispatch(actions.deleteResource(id)).then(() => {
            // refresh list after deletion
            dispatch(actions.fetchResources(uiProps.queryParams));
            // clear selections list
            uiProps.setIds([]);
            // closing delete modal
            onHide();
        });
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {isLoading && <ModalProgressBar variant="query"/>}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Resource Delete
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoading && (
                    <span><FormattedMessage id="APPLICATION.COMMON.TITLE.SINGLE_DELETE_ASK"/></span>
                )}
                {isLoading && <span><FormattedMessage id="APPLICATION.COMMON.TITLE.DELETING_RESOURCE"/></span>}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        <FormattedMessage id="APPLICATION.COMMON.ACTIONS.ABORT"/>
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={deleteProduct}
                        className="btn btn-danger btn-elevate"
                    >
                        <FormattedMessage id="APPLICATION.COMMON.ACTIONS.DELETE"/>
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
