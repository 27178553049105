import {withRouter} from "react-router-dom";
import {useReLogin} from "../../../../_the_law/hooks";
import {useUIContext} from "../../CrudFormPage/UIContext";
import React, {useEffect, useMemo} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as uiHelpers from "../../CrudFormPage/UIHelpers";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {Pagination} from "../../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage} from "../../../../_metronic/_helpers";

export const TableList = withRouter(({location, columns}) => {

    const reLogin = useReLogin()

    // Products UI Context
    const uiContext = useUIContext();
    const {actions} = uiContext;
    const {queryParams, ids, setQueryParams, setIds} = useMemo(() => {
        return {
            ids: uiContext.ids,
            setIds: uiContext.setIds,
            queryParams: uiContext.queryParams,
            setQueryParams: uiContext.setQueryParams,
            editResource: uiContext.editResource,
            deleteSingleResource: uiContext.deleteSingleResource,
        };
    }, [uiContext]);

    // Getting curret state of products list from store (Redux)
    const {currentState} = useSelector(
        (state) => ({currentState: state[uiContext.storeSection]}),
        shallowEqual
    );
    const {totalCount, entities, listLoading, actionsLoading} = currentState;
    // Products Redux state
    const dispatch = useDispatch();
    useEffect(() => {
        if (!reLogin) {
            // clear selections list
            setIds([]);
            // server call by queryParams
            dispatch(actions.fetchResources(queryParams));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }

    }, [queryParams, reLogin]);

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.per_page,
        page: queryParams.page,
    };

    return (
        <fieldset disabled={listLoading || actionsLoading}>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({paginationProps, paginationTableProps}) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                pagination={{}}
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="id"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    setQueryParams
                                )}
                                // selectRow={getSelectRow({
                                //     entities,
                                //     ids: ids,
                                //     setIds: setIds,
                                // })}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities}/>
                                <NoRecordsFoundMessage entities={entities}/>
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </fieldset>
    );
});
