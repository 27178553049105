import {
    GROUP_LIST_URL,
    SCHOOLS_LIST_URL,
    STUDENT_LIST_URL,
    SUPERVISOR_LIST_URL
} from "../../modules/Application/constants";
import {RULES, RULES_GROUPS} from "../../../_the_law/_helpers/MustHaveRules";
import {CONTENT_CATEGORY_LIST_URL, CONTENT_POST_LIST_URL, GAME_LIST_URL} from "../../modules/Application/constants";

export const school = {
    url: SCHOOLS_LIST_URL,
    label: "APPLICATION.GENERAL.SCHOOLS",
    rules: [RULES.ADMIN]
};

export const contentCategory = {
    url: CONTENT_CATEGORY_LIST_URL,
    label: "APPLICATION.GENERAL.CONTENT_CATEGORIES",
    rules: [RULES.ADMIN]
};

export const contentPost = {
    url: CONTENT_POST_LIST_URL,
    label: "APPLICATION.GENERAL.CONTENT_POSTS",
    rules: [RULES.ADMIN]
};

export const gameRoom = {
    url: GAME_LIST_URL,
    label: "APPLICATION.GENERAL.GAME_ROOMS",
    rules: RULES_GROUPS.CAN_MANAGE_GAME
};

export const resourceEbook = {
    url: "https://resource.selyapp.com/resource.pdf",
    type: "link",
    target: "_blank",
    label: "APPLICATION.GENERAL.RESOURCES_EBOOK",
    rules: RULES_GROUPS.CAN_MANAGE_GAME,
    classes: ["text-primary"]
};

export const resourceInteractive = {
    url: "https://resource.selyapp.com/",
    type: "link",
    target: "_blank",
    label: "APPLICATION.GENERAL.RESOURCES_INTERACTIVE",
    rules: RULES_GROUPS.CAN_MANAGE_GAME,
    classes: ["text-primary"]
};

export const supervisor = {
    url: SUPERVISOR_LIST_URL,
    label: "APPLICATION.GENERAL.SUPERVISORS",
    rules: [ RULES.SCHOOL]
};

export const students = {
    url: STUDENT_LIST_URL,
    label: "APPLICATION.GENERAL.STUDENTS",
    rules: [ RULES.SCHOOL, RULES.SUPERVISOR]
};

export const groups = {
    url: GROUP_LIST_URL,
    label: "APPLICATION.GENERAL.GROUPS",
    rules: [ RULES.SCHOOL, RULES.SUPERVISOR]
};
