import {resourcesCrudFactory} from "./resourcesCrud";
import {resourcesSliceFactory} from "./resourcesSlice";
import {resourcesCrudActionsFactory} from "./resourcesActions";
import {identity} from 'lodash';

const initCrudArgs = {
    findUrl: "",
    additional_crud: {}
};

export const quickSlice = (
    {
        name,
        endpoint,
        onActionEvents: {
            onDelete = [],
            onResourcesFetch = () => {
            },
            onCreateResource = () => {
            },
            additionalActions = (requests, actions) => ({})
        } = {
            onDelete: [],
            onResourcesFetch: () => {
            },
            onCreateResource: () => {
            },
            additionalActions: (requests, actions) => ({})
        },
        onSliceEvents = {},
        crudUrls = {
            additional_crud: {},
        },
        additionalHeaders = {
            onCreate: {},
            onUpdate: {},
        },
        dataTransform = identity,
        additionalConfigs = {
            onCreate: {},
            onUpdate: {},
        },
    }
) => {
    const crud = resourcesCrudFactory(endpoint, {
        ...initCrudArgs,
        ...crudUrls,
        additionalHeaders,
        dataTransform,
        additionalConfigs,
    });

    const slice = resourcesSliceFactory(name, onSliceEvents);

    const actions = resourcesCrudActionsFactory(crud, slice.actions, {
        additionalActions,
        onCreateResource,
        onResourcesFetch,
        onDelete
    });


    return {
        crud,
        slice,
        actions
    }
}


export function updateEntities(state, resource) {
    state.entities = state.entities.map(entity => {
        if (entity.id === resource.id) {
            return resource;
        }
        return entity;
    });
}

export function endResourceFetch(state, action) {
    state.error = null;
    state.actionsLoading = false;
    state.resourceForEdit = action.payload.resource;
    updateEntities(state, action.payload.resource);
}
