import React from "react";
import {getIcon} from "../utils/icons";
import {ADMINISTRATION_URL, CONTENT_URL, GAME_ADMIN_URL, STUDENTS_ADMINISTRATION_URL} from "../../modules/Application/constants";
import {contentCategory, contentPost, gameRoom, groups, resourceEbook, resourceInteractive, school, students, supervisor} from "./subMenus";
import {RULES, RULES_GROUPS} from "../../../_the_law/_helpers/MustHaveRules";
import {FormattedMessage} from "react-intl";

const icons = getIcon.from;
export const adminMenu = {
    icon: icons.Administration("Administration", 'png'),
    type: 'png',
    title: <FormattedMessage
        id="MENU.GENERAL.ADMINISTRATION"
    />,
    to: ADMINISTRATION_URL,
    multiple: true,
    rules: RULES_GROUPS.CAN_MANAGE,
    submenus: [school, supervisor]
};
export const studentMenu = {
    icon: icons.Students("Students", 'png'),
    type: 'png',
    title: <FormattedMessage
        id="MENU.GENERAL.STUDENTS"
    />,
    to: STUDENTS_ADMINISTRATION_URL,
    multiple: true,
    rules: [RULES.SUPERVISOR, RULES.SCHOOL],
    submenus: [students, groups]
};
export const contentMenu = {
    icon: icons.Contents("Contents", 'png'),
    type: 'png',
    title: <FormattedMessage
        id="MENU.GENERAL.CONTENTS"
    />,
    to: CONTENT_URL,
    multiple: true,
    rules: [RULES.ADMIN],
    submenus: [contentPost, contentCategory]
};
export const gameMenu = {
    icon: icons.Game("Game", 'png'),
    type: 'png',
    title: <FormattedMessage
        id="MENU.GENERAL.GAME"
    />,
    to: GAME_ADMIN_URL,
    multiple: true,
    rules: RULES_GROUPS.CAN_MANAGE_GAME,
    submenus: [gameRoom, resourceEbook, resourceInteractive]
};
