import {NavLink, useLocation} from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
import React, {memo} from "react";
import {AsideSubMenuListItem} from "./AsideSubMenuListItem";
import {MustHaveRules} from "../../../../../_the_law/_helpers/MustHaveRules";
import {useIntl} from "react-intl";

const ListItemBody = memo(({multiple = false, title, icon, to, submenus = []}) => {
    const intl = useIntl();

    return <>
        <NavLink className={`menu-link ${multiple ? 'menu-toggle' : ''}`} to={to}>
            <span className="svg-icon menu-icon">
                {icon}
            </span>
            <span className="menu-text">{title}</span>
            {multiple && <i className="menu-arrow"/> || null}
        </NavLink>
        {
            multiple && submenus.length > 0 && <div className="menu-submenu">
                <i className="menu-arrow"/>
                <ul className="menu-subnav">
                    <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{title}</span>
                </span>
                    </li>
                    {
                        submenus.map(({url, label, rules = [], type = 'menu', target = null, classes=[]}, i) => {

                            return <MustHaveRules rules={rules} key={i}>
                                <AsideSubMenuListItem
                                    type={type}
                                    target={target}
                                    classes={classes}
                                    url={url}
                                    label={intl.formatMessage({id: label})}
                                />
                            </MustHaveRules>
                        })
                    }
                </ul>
            </div>
        }
    </>
});


export default ({multiple = false, title, icon, to, submenus = [], type = 'menu', target = null}) => {
    const location = useLocation();
    const menuItemActive = checkIsActive(location, to)
        ? " menu-item-active menu-item-open "
        : "";


    return <li
        className={`menu-item ${multiple ? 'menu-item-submenu' : ''} ${menuItemActive}`}
        aria-haspopup="true"
        {...(multiple ? {'data-toggle-menu': 'hover'} : {})}
    >
        <ListItemBody
            multiple={multiple}
            title={title}
            icon={icon}
            to={to}
            submenus={submenus}
        />
    </li>;
}
