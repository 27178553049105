import {listResourceUrl} from "../Helpers";

export const PAGE_URL = '/backoffice';
export const ADMINISTRATION_URL = `${PAGE_URL}/admin`;
export const CONTENT_URL = `${PAGE_URL}/contents`;
export const GAME_ADMIN_URL = `${PAGE_URL}/game`;

export const SCHOOLS_URL = `${ADMINISTRATION_URL}/schools`;
export const SCHOOLS_LIST_URL = listResourceUrl(SCHOOLS_URL);

export const CONTENT_CATEGORY_URL = `${CONTENT_URL}/content_categories`;
export const CONTENT_CATEGORY_LIST_URL = listResourceUrl(CONTENT_CATEGORY_URL);

export const CONTENT_POST_URL = `${CONTENT_URL}/content_posts`;
export const CONTENT_POST_LIST_URL = listResourceUrl(CONTENT_POST_URL);

export const SUPERVISOR_URL = `${ADMINISTRATION_URL}/supervisor`;
export const SUPERVISOR_LIST_URL = listResourceUrl(SUPERVISOR_URL);

export const GAME_URL = `${GAME_ADMIN_URL}/sessions`;
export const GAME_LIST_URL = listResourceUrl(GAME_URL);

export const STUDENTS_ADMINISTRATION_URL = `${PAGE_URL}/admin`;

export const STUDENT_URL = `${STUDENTS_ADMINISTRATION_URL}/student`;
export const STUDENT_LIST_URL = listResourceUrl(STUDENT_URL);

export const GROUP_URL = `${STUDENTS_ADMINISTRATION_URL}/group`;
export const GROUP_LIST_URL = listResourceUrl(GROUP_URL);

export const HOME = STUDENT_LIST_URL;
