import {endResourceFetch, quickSlice, updateEntities} from "../../../../setup/redux/slice/quickSlice";
import {GAME_ROOMS_ENDPOINT, GAME_ROOMS_REDUX_SECTION_NAME} from "../../_constants";
import Axios from "axios";
import {callTypes} from "../../../../setup/redux/slice/resourcesSlice";

export const gameRoomSlice = quickSlice({
    name: GAME_ROOMS_REDUX_SECTION_NAME,
    endpoint: GAME_ROOMS_ENDPOINT,
    onSliceEvents: {
        additionalState: {
            meta: {
                groups: [],
                students: [],
                supervisors: []
            },
            newPlayers: 0
        },
        onResourcesFetched(state, action) {
            state.meta.groups = action?.payload?.meta?.groups ?? [];
            state.meta.students = action?.payload?.meta?.students ?? [];
            state.meta.supervisors = action?.payload?.meta?.supervisors ?? [];
        },
        additionalReducers: {
            participationChanged: endResourceFetch,
            gameStateChanged: endResourceFetch,
            newPlayerAdded: (state) => {
                state.newPlayers++;
            },
            clearNewPlayers: state => {
                state.newPlayers = 0;
            },
        }
    },
    onActionEvents: {
        additionalActions: (requests, actions) => ({
            addNewPlayer: () => dispatch => {
                dispatch(actions.newPlayerAdded());
            },
            newPlayerRead: () => dispatch => {
                dispatch(actions.clearNewPlayers());
            },
            changeGroupParticipation: ({id, group_id, enable}) => dispatch => {
                dispatch(actions.startCall({callType: callTypes.action}));
                requests.changeGroupParticipation({id, group_id, enable}).then(response => {
                    const resource = response.data;
                    return dispatch(actions.participationChanged({resource}));
                }).catch(error => {
                    error.clientMessage = "Can't change participation of group";
                    return dispatch(actions.catchError({error, callType: callTypes.action}));
                });
            },
            changeStudentParticipation: ({id, student_id, enable}) => dispatch => {
                dispatch(actions.startCall({callType: callTypes.action}));
                requests.changeStudentParticipation({id, student_id, enable}).then(response => {
                    const resource = response.data;
                    return dispatch(actions.participationChanged({resource}));
                }).catch(error => {
                    error.clientMessage = "Can't change participation of student";
                    return dispatch(actions.catchError({error, callType: callTypes.action}));
                });
            },
            changeAllParticipation: ({id, enable}) => dispatch => {
                dispatch(actions.startCall({callType: callTypes.action}));
                requests.changeAllParticipation({id, enable}).then(response => {
                    const resource = response.data;
                    return dispatch(actions.participationChanged({resource}));
                }).catch(error => {
                    error.clientMessage = "Can't change participation of game room";
                    return dispatch(actions.catchError({error, callType: callTypes.action}));
                });
            },
            changeGameState: ({id,enable}) => dispatch => {
                dispatch(actions.startCall({callType: callTypes.action}));
                requests.changeGameState({id, enable}).then(response => {
                    const resource = response.data;
                    return dispatch(actions.gameStateChanged({resource}));
                }).catch(error => {
                    error.clientMessage = "Can't change state of game room";
                    return dispatch(actions.catchError({error, callType: callTypes.action}));
                });
            }
        })
    },
    crudUrls: {
        additional_crud: {
            changeGroupParticipation: (base_url) => ({id, group_id, enable}) => {
                return Axios.post(`${base_url}/${id}/group/${group_id}/participation`, {enable})
            },
            changeStudentParticipation: (base_url) => ({id, student_id, enable}) => {
                return Axios.post(`${base_url}/${id}/student/${student_id}/participation`, {enable})
            },
            changeAllParticipation: (base_url) => ({id, enable}) => {
                return Axios.post(`${base_url}/${id}/participation`, {enable})
            },
            changeGameState: baseUrl => ({id,enable}) => {
                return Axios.post(`${baseUrl}/${id}/playing`, {enable})
            }
        }
    }
})
