import {Col, Row} from "react-bootstrap";
import {SchoolSelect} from "../../partials/SchoolSelect";
import {GenericSearch} from "../../partials/Filter/GenericSearch";
import React from "react";
import {MustHaveRules, RULES} from "../../../../../../_the_law/_helpers/MustHaveRules";
import {FormattedMessage} from "react-intl";
import {AsOption} from "../../../../partials/FormattedMessages";

export const filterFields = <Row>
    <MustHaveRules rules={[RULES.ADMIN]}>
        <Col md={4}>
            <SchoolSelect
                emptyOption={<AsOption value=""><FormattedMessage
                    id="FILTERS.EMPTY_OPTION.ALL_SCHOOLS"
                /></AsOption>}
                submitOnChange={true}
            />
        </Col>
    </MustHaveRules>
    <Col>
        <GenericSearch/>
    </Col>
</Row>;
