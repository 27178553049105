import {GAME_LIST_URL} from "../../constants";
import React from "react";
import {GAME_ROOMS_REDUX_SECTION_NAME} from "../../_constants";
import {Edit} from "../../../partials/Edit";
import {fields} from "./fields";
import {gameRoomSlice} from "../../_redux/gameRoom/gameRoomSlice";
import {GameRoomTable} from "./Table";
import {editResourceUrl} from "../../../Helpers";
import {ParticipantModal} from "./partials/ParticipantModal";
import {GameRoomAnalytics} from "./Analytics";
import {Profiler} from "./Profiler";
import {FormattedMessage} from "react-intl";


export default {
    storeSection: GAME_ROOMS_REDUX_SECTION_NAME,
    actions: gameRoomSlice.actions,
    base_url: GAME_LIST_URL,
    listCmp: GameRoomTable,
    filterCmp: () => <></>,
    newCmp: Edit,
    editCmp: Edit,
    formProps: {
        initValues: {
            name: '',
            students: [],
            groups: [],
            supervisor_id: '',
            groups_abilitated: []
        },
        fields
    },
    alias: {
        newTitle: <FormattedMessage
            id="PAGES.TITLE.NEW_GAME_ROOM"
        />,
        editTitle: <FormattedMessage
            id="PAGES.TITLE.EDIT_GAME_ROOM"
        />,
        listTitle: <FormattedMessage
            id="PAGES.TITLE.GAME_ROOMS_LIST"
        />
    },
    filter_set: {
        sortField: "id",
    },
    cardActions: [],
    rules: {
        canCreate: []
    },
    additionalRoutes: [
        {
            path: `${editResourceUrl(GAME_LIST_URL)}/abilitation`,
            component: ParticipantModal
        },
        {
            path: `${editResourceUrl(GAME_LIST_URL)}/profiler/:student`,
            component: Profiler,
        },
        {
            path: `${editResourceUrl(GAME_LIST_URL)}/analytics`,
            component: GameRoomAnalytics,
            exact: true
        },
    ]
};
