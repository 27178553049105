import {useSelector} from "react-redux";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import {Select} from "../../../../../_metronic/_partials/controls";
import {AsOption} from "../../../partials/FormattedMessages";
import React from "react";

export const SchoolSelect = ({emptyOption = null, submitOnChange = false, metaSection = 'supervisors'}) => {

    const schools = useSelector(state => state?.[metaSection]?.meta?.schools ?? [])

    return <Field
        label={<FormattedMessage
            id="APPLICATION.SUPERVISOR.SCHOOL_OWNER"
        />}
        name="school"
        key="school"
    >
        {
            ({field, form}) => {
                return <Select value={field.value ?? ''}
                               onChange={e => {
                                   form.setFieldValue(field.name, e.currentTarget.value);

                                   if (submitOnChange) {
                                       form.submitForm().then(r => {
                                       });
                                   }

                               }}>
                    {emptyOption || <AsOption>
                        <FormattedMessage
                            id="APPLICATION.SUPERVISOR.SELECT_SCHOOL"
                        />
                    </AsOption>}
                    {
                        schools.map(el => <option value={el.id}>{el.name}</option>)
                    }
                </Select>;
            }
        }
    </Field>;
};
