import React from "react";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import {useFormikContext} from "formik";
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {GAME_ROOMS_REDUX_SECTION_NAME} from "../../_constants";
import {useIntl} from "react-intl";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        minHeight: 50
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

export const GroupsSelection = ({metaSection = GAME_ROOMS_REDUX_SECTION_NAME, name = 'groups', placeholder = "INPUT.SELECTION.GROUPS_IN_GAME_ROOM"}) => {

    const {values, setFieldValue} = useFormikContext();

    const intl = useIntl();

    placeholder = intl.formatMessage({id: placeholder});

    const groups = useSelector(state => state?.[metaSection]?.meta?.groups ?? []);

    const chipsGroup = values[name] ?? []

    const classes = useStyles();
    return <Row>
        <Col md={12}>
            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                    value={chipsGroup}
                    onChange={(event, newValue) => {
                        setFieldValue(name, newValue);
                    }}
                    disableCloseOnSelect
                    options={groups.filter(el => chipsGroup.map(el => el.id).indexOf(el.id) < 0)}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                className={classes.chip}
                                label={option.name}
                                onDelete={() => {
                                    const newVal = [
                                        ...chipsGroup.slice(0, chipsGroup.indexOf(option)),
                                        ...chipsGroup.slice(chipsGroup.indexOf(option) + 1),
                                    ];

                                    setFieldValue(name, newVal);
                                }}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField variant={"outlined"} {...params} label={placeholder}/>
                    )}
                />
            </FormControl>
        </Col>
    </Row>
}
