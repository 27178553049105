import {Breadcrumbs, Typography} from "@material-ui/core";
import {ProgressBar} from "react-bootstrap";
import React from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export const ProfileProgress = ({
                                    as = ({children}) => <>{children}</>,
                                    hierarchy = [
                                        "Game Room",
                                        "cattle-5285",
                                        "Recognition"
                                    ],
                                    progress = 0,
                                    props = {},
                                }) => {


    const Component = as;

    return <Component>

        <Breadcrumbs>
            {
                hierarchy.map(el => <Typography>{el}</Typography>)
            }
        </Breadcrumbs>
        <div>
            <ProgressBar {...props?.progress} now={progress} label={`${progress}%`}/>
        </div>
    </Component>
}
