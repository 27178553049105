import {TableList} from "../../../../partials/Table";
import {FormattedMessage} from "react-intl";
import {sortCaret} from "../../../../../../_metronic/_helpers";
import React from "react";
import * as columnFormatters from "../../../../CrudFormPage/table/column-formatters";
import {SupervisorName} from "./SupervisorName";

export const GroupTable = () => <TableList columns={[
    {
        dataField: 'id',
        text: <FormattedMessage
            id="APPLICATION.COMMON.ID"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: 'name',
        text: <FormattedMessage
            id="APPLICATION.GROUP.NAME"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: 'supervisor',
        text: <FormattedMessage
            id="APPLICATION.GROUP.SUPERVISOR"
        />,
        sort: true,
        sortCaret,
        formatter: (row) => {
            return <SupervisorName id={row}/>
        }
    },
    {
        dataField: "action",
        text: <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.COLUMNS.ACTIONS"/>,
        formatter: columnFormatters.ActionsColumnFormatter,
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    },
]}/>
