import {ProfileProgress} from "./ProfilerProgress";
import {ListGroupItem} from "react-bootstrap";
import React from "react";

export const ProfilerListGroupItem = ({
                                          hierarchy,
                                          progress,
                                          variant
                                      }) => {

    return <ProfileProgress
        as={ListGroupItem}
        hierarchy={hierarchy}
        progress={progress}
        asProps={{
            className: 'profiler-row'
        }}
        props={
            {
                progress: {
                    variant
                }
            }
        }
    />
}
