import {Col, Row} from "react-bootstrap";
import {MustBeAdmin} from "../../../../../../_the_law/_helpers/MustHaveRules";
import {SchoolSelect} from "../../partials/SchoolSelect";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../../../_metronic/_partials/controls";
import React from "react";

export const fields = <Row className="inputs-rows mt-5">
    <MustBeAdmin>
        <Col md={12}>
            <SchoolSelect/>
        </Col>
    </MustBeAdmin>
    <Col md={12}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.COMMON.EMAIL"
            />}
            component={Input}
            name="email"
            key="email"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SUPERVISOR.FIRST_NAME"
            />}
            component={Input}
            name="supervisor.first_name"
            key="supervisor.first_name"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.SUPERVISOR.LAST_NAME"
            />}
            component={Input}
            name="supervisor.last_name"
            key="supervisor.last_name"
        />
    </Col>
    {/*<Col md={12}>*/}
    {/*    <Field*/}
    {/*        label={<FormattedMessage*/}
    {/*            id="APPLICATION.SUPERVISOR.FISCAL_CODE"*/}
    {/*        />}*/}
    {/*        component={Input}*/}
    {/*        name="supervisor.fiscal_code"*/}
    {/*        key="supervisor.fiscal_code"*/}
    {/*    />*/}
    {/*</Col>*/}
</Row>;
