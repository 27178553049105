import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useUIContext} from "../../../../CrudFormPage/UIContext";
import {useDispatch, useSelector} from "react-redux";
import {DebounceInput} from "react-debounce-input";
import {ParticipantList} from "./ParticipantList";
import {FormattedMessage} from "react-intl";


export const SearchIn = ({handleChange = ''}) => {

    return <DebounceInput
        debounceTimeout={300}
        type="text"
        className="form-control"
        name="searchText"
        placeholder="Search"
        onBlur={handleChange}
        value={""}
        onChange={handleChange}
    />;
}


export const ParticipantModal = ({
                                     match = {
                                         params: {
                                             id: null
                                         },
                                     },
                                     show = true,
                                     onHide = null
                                 }) => {

    const {actions, back, storeSection,} = useUIContext();

    const {params: {id = null},} = match;

    const {
        isLoading, actionsLoading,
    } = useSelector(state => state[storeSection])


    const resourceForEdit = useSelector(state => {
        const gameRoom = state[storeSection]?.resourceForEdit;

        return {
            game_room_id: gameRoom?.id ?? '',
            groups: gameRoom?.groups ?? [],
            slug: gameRoom?.slug ?? '',
            tags: gameRoom?.tags ?? [],
            nonce: gameRoom?.nonce ?? '',
        }

    })


    const [searching, setSearching] = useState("");

    const dispatch = useDispatch();

    const initResource = () => {
        dispatch(actions.fetchResource(id))
    }

    useEffect(() => {
        initResource();
    }, [id])

    let resourceGroups = [...(resourceForEdit?.groups ?? [])].map(group => {
        group = {
            ...group
        };

        group.students = [...group.students].filter(student => student.name.toLowerCase().indexOf(searching.toLowerCase()) >= 0);

        return group;
    }).filter(group => {


        const inGroupName = group.name.toLowerCase().indexOf(searching.toLowerCase()) >= 0;
        const inStudentsName = group.students.length > 0;

        return inGroupName || inStudentsName;

    });

    const enabledStatus = `game-room-${resourceForEdit?.slug ?? ''}-status-enabled`;
    const allEnabledStatus = `game-room-${resourceForEdit?.slug ?? ''}-all-enabled`;

    const allEnabled = (resourceForEdit?.tags ?? []).indexOf(allEnabledStatus) >= 0;

    return <fieldset disabled={isLoading || actionsLoading}>
        <Modal show={show} onHide={onHide ?? back}>
            <Modal.Header><FormattedMessage
                id="PAGES.GAME_ROOM.PERMISSIONS_CONTROL_TITLE"
            /></Modal.Header>
            <Modal.Body style={{minHeight: '75vh'}}>
                <SearchIn handleChange={e => setSearching(e.target.value)}/>
                <ParticipantList
                    onNewPresence={initResource}
                    disabled={isLoading || actionsLoading}
                    allEnabled={allEnabled}
                    enabledStatus={enabledStatus}
                    resourceGroups={resourceGroups}
                    gameRoomId={resourceForEdit?.game_room_id}
                    slug={resourceForEdit?.slug}
                />
            </Modal.Body>
        </Modal>
    </fieldset>;
};
