const {REACT_APP_API_MOCKED} = process.env;
export const forApiMock = (callback, alternative = null) => {



    const positive = REACT_APP_API_MOCKED === 'mock' &&
        ((typeof callback === "function" && callback()) || callback);
    const negative = REACT_APP_API_MOCKED === 'service' && alternative &&
        ((typeof alternative === "function" && alternative()) || alternative);

    return positive || negative || null;
};

export const basedApiMock = (callback, alternative) => `${forApiMock(process.env.REACT_APP_API_MOCK, process.env.REACT_APP_BASE_API)}/${forApiMock(callback, alternative)}`;
