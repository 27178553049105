import React, {createContext, useCallback, useContext, useState} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter, useUiEvents} from "./UIHelpers";
import {FormattedMessage} from "react-intl";


const baseAliases = {
    newTitle: <FormattedMessage id="APPLICATION.COMMON.NEW_RESOURCE"/>,
    listTitle: "List Resources"
};

export const UIContext = createContext({});

export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({rules, actions, alias, filters_set = initialFilter, base_url, storeSection, children, formProps}) => {

    const uiEvents = useUiEvents({base_url});

    const [queryParams, setQueryParamsBase] = useState(filters_set);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);


    const value = {
        rules,
        alias: {
            ...baseAliases,
            ...alias
        },
        actions,
        base_url,
        storeSection,
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        formProps,
        back: uiEvents.back,
        newResource: uiEvents.newResource,
        editResource: uiEvents.editResource,
        deleteSingleResource: uiEvents.deleteSingleResource,
        deleteMultipleResource: uiEvents.deleteMultipleResource,
        fetchResource: uiEvents.fetchResource,
        updateStatusResource: uiEvents.updateStatusResource
    };

    return (
        <UIContext.Provider value={value}>
            {children}
        </UIContext.Provider>
    );
};

export const UIConsumer = ({children}) => <UIContext.Consumer>
    {
        value => React.cloneElement(children, value)
    }
</UIContext.Consumer>;
