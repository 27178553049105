import React, {useState} from "react";
import {useFormik} from "formik";
import {connect} from "react-redux";
import * as Yup from "yup";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {register} from "../_redux/authCrud";
import {Col, Row} from "react-bootstrap";
import {map} from 'lodash';

const initialValues = {
    name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    state: '',
    district: ''
};


const RegistrationInputField = ({formik, getInputClasses, name, placeholder, type = 'text', inputPros = {}}) => {

    return <div className="form-group fv-plugins-icon-container">
        <input
            placeholder={placeholder}
            type={type}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(name)}`}
            name={name}
            {...formik.getFieldProps(name)}
            {...inputPros}
        />
        {formik.touched[name] && formik.errors[name] ? (
            <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors[name]}</div>
            </div>
        ) : null}
    </div>
}

const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
]

function Registration(props) {

    const signup_success_text = <h3>
        La tua richiesta di registrazione è stata presa in carico.
        <br/>
        Riceverai un email appena il tuo account sarà abilitato.
    </h3>

    const {intl} = props;
    const [loading, setLoading] = useState(false);
    const RegistrationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(80, "Maximum 80 symbols")
            .required(
                "Field required"
            ),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                "Field required"
            ),
        first_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                "Field required"
            ),
        last_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                "Field required"
            ),
        password: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(30, "Maximum 50 symbols")
            .required(
                "Field required"
            ),
        password_confirmation: Yup.string()
            .required(
                "Field required"
            )
            .when("password", {
                is: (val) => (val && val.length > 0),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
        state: Yup.string()
            .required(
                "Field required"
            ),
        district: Yup.string()
            .required(
                "Field required"
            ),

    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const [signupSuccess, setSignupSuccess] = useState(false)

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            register(values)
                .then(({data: {token = null, accessToken = null}}) => {
                    disableLoading();
                    setStatus(null);
                    setSignupSuccess(true)
                })
                .catch(reason => {

                    let errors = [
                        <div>Errore nella registrazione</div>
                    ];


                    if (reason?.response?.data?.errors) {
                        map(reason?.response?.data?.errors, el => errors.push(<div>{el[0]}</div>))
                    }

                    setSubmitting(false);
                    setStatus(errors);
                    disableLoading();
                });
        },
    });


    return (
        <div className="login-form" style={{display: "block", maxWidth: 700}}>
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.REGISTER.TITLE"/>
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter the details to register the school
                </p>
            </div>

            <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
                autoComplete="off"
            >
                {/* begin: Alert */}
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}
                {/* end: Alert */}

                {
                    (signupSuccess &&
                        <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                            <div className="alert-text font-weight-bold">{signup_success_text}</div>
                        </div>
                    ) || <Row>
                        <Col xs={12}>
                            <RegistrationInputField
                                name="name"
                                formik={formik}
                                getInputClasses={getInputClasses}
                                placeholder="Organizaziont's name"
                            />
                        </Col>

                        <Col xs={6}>
                            <RegistrationInputField
                                name="first_name"
                                formik={formik}
                                getInputClasses={getInputClasses}
                                placeholder="First name"
                            />
                        </Col>

                        <Col xs={6}>
                            <RegistrationInputField
                                name="last_name"
                                formik={formik}
                                getInputClasses={getInputClasses}
                                placeholder="Last name"
                            />
                        </Col>

                        <Col xs={6}>
                            <div className="form-group fv-plugins-icon-container">
                                <select
                                    placeholder="State"
                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('state')}`}
                                    name="state"
                                    {...formik.getFieldProps("state")}>
                                    <option value="">Select state</option>
                                    {
                                        states.map(el => <option value={el}>{el}</option>)
                                    }
                                </select>
                                {formik.touched["state"] && formik.errors["state"] ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors["state"]}</div>
                                    </div>
                                ) : null}
                            </div>
                        </Col>


                        <Col xs={6}>
                            <RegistrationInputField
                                name="address"
                                formik={formik}
                                getInputClasses={getInputClasses}
                                placeholder="Address"
                            />
                        </Col>
                        {/*<Col xs={6}>*/}
                        {/*    <RegistrationInputField*/}
                        {/*        name="district"*/}
                        {/*        formik={formik}*/}
                        {/*        getInputClasses={getInputClasses}*/}
                        {/*        placeholder="District"*/}
                        {/*    />*/}
                        {/*</Col>*/}

                        <Col xs={12}>
                            <RegistrationInputField
                                name="email"
                                type="email"
                                formik={formik}
                                inputPros={{
                                    autoComplete: "new-email"
                                }}
                                getInputClasses={getInputClasses}
                                placeholder="Email address"
                            />
                        </Col>

                        <Col xs={6}>
                            <RegistrationInputField
                                name="password"
                                type="password"
                                inputPros={{
                                    autoComplete: "new-password"
                                }}
                                formik={formik}
                                getInputClasses={getInputClasses}
                                placeholder="Password"
                            />
                        </Col>

                        <Col xs={6}>
                            <RegistrationInputField
                                name="password_confirmation"
                                type="password"
                                inputPros={{
                                    autoComplete: "new-password"
                                }}
                                formik={formik}
                                getInputClasses={getInputClasses}
                                placeholder="Confirm password"
                            />
                        </Col>


                    </Row>
                }

                {
                    !signupSuccess && <div className="form-group d-flex flex-wrap flex-center">
                        <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            <span>Submit</span>
                            {loading && <span className="ml-3 spinner spinner-white"/>}
                        </button>

                        <Link to="/auth/login">
                            <button
                                type="button"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                Cancel
                            </button>
                        </Link>
                    </div>
                }
            </form>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Registration));
