/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {AsideMenuItem} from "./AsideMenuItem";
import {useAsideMenusConfig} from "../../../../../app/setup/asideMenu";


export const AsideMenuList = ({layoutProps}) => {
    const menus = useAsideMenusConfig();
    return (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {menus.map((props,index) => <AsideMenuItem key={index} {...props}/>)}
        </ul>
    );
};
