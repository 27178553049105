import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";
import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../../../app/modules/Auth/_redux";
import {FormattedMessage, useIntl} from "react-intl";
import {visitSlice} from "../../../../../app/modules/Application/_redux/visit/visitSlice";


export const BackFromVisit = () => {

    const dispatch = useDispatch();

    const backToken = useSelector(state => state.auth.backToken);

    const handleVisit = (e) => {
        e.preventDefault();
        return dispatch(actions.login(backToken));
    };

    return (backToken && <a href="#" onClick={handleVisit} className="navi-item">
            <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                    <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/General/Notification2.svg"
                        )}
                    ></SVG>
                  </span>
                    </div>
                </div>
                <div className="navi-text">
                    <div className="font-weight-bold">Back to Admin</div>
                </div>
            </div>
        </a>
    ) || null;
}


export const QuickVisit = () => {

    const dispatch = useDispatch();


    const [search, setSearch] = useState('');

    const users = useSelector(state => state.visit.entities).filter(el => search === '' || el.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);

    useEffect(() => {
        dispatch(visitSlice.actions.fetchResources());
    }, [1])

    const handleVisit = (user_id) => (e) => {
        e.preventDefault();
        return dispatch(visitSlice.actions.createResource({user_id}));
    };

    const intl = useIntl();



    return <Fragment>
        <h4><FormattedMessage
            id="DRAWER.ADMIN.VISIT_USER"
        /></h4>
        <input onChange={e => setSearch(e.currentTarget.value)} placeholder={intl.formatMessage({id: "COMMON.SEARCH_USER"})} type="text" className="form-control"/>
        {
            users.slice(0, 4).map(user => (user.id && <a href="#" onClick={handleVisit(user.id)} className="navi-item" key={user.id}>
                <div className="navi-link">
                    <div className="symbol symbol-40 bg-light mr-3">
                        <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/General/Notification2.svg"
                        )}
                    ></SVG>
                  </span>
                        </div>
                    </div>
                    <div className="navi-text">
                        <div className="font-weight-bold">
                            {user.name}
                        </div>
                        <div className="text-muted">
                            id: {user.id}
                        </div>
                    </div>
                </div>
            </a>) || null)
        }
    </Fragment>
}
