import {Col, Row} from "react-bootstrap";
import {SchoolSelect} from "../../partials/SchoolSelect";
import {GenericSearch} from "../../partials/Filter/GenericSearch";
import React from "react";
import {SupervisorSelect} from "../../partials/SupervisorSelect";
import {MustHaveRules, RULES} from "../../../../../../_the_law/_helpers/MustHaveRules";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../../_metronic/_helpers";
import {SwitchField} from "../../partials/Filter/fileds/Switch";
import {FormattedMessage} from "react-intl";
import {AsOption} from "../../../../partials/FormattedMessages";

export const filterFields = <Row>
    <MustHaveRules rules={[RULES.ADMIN]}>
        <Col>
            <SchoolSelect
                emptyOption={<AsOption value=""><FormattedMessage
                    id="FILTERS.EMPTY_OPTION.ALL_SCHOOLS"
                /></AsOption>}
                submitOnChange={true}
                metaSection="students"
            />
        </Col>
    </MustHaveRules>
    <MustHaveRules rules={[RULES.ADMIN, RULES.SCHOOL]}>
        <Col>
            <SupervisorSelect
                emptyOption={<AsOption value=""><FormattedMessage
                    id="FILTERS.EMPTY_OPTION.ALL_SUPERVISORS"
                /></AsOption>}
                submitOnChange={true}
                metaSection="students"
            />
        </Col>
    </MustHaveRules>
    <Col xs={"auto"}>
        <SwitchField
            options={[
                {
                    label: <span>---</span>,
                    value: "",
                },
                {
                    label: <span> M</span>,
                    value: "m",
                },
                {
                    label: <span> F</span>,
                    value: "f",
                },
                {
                    label: <span> U</span>,
                    value: "n",
                },
            ]}
            name="genre"
            label=<FormattedMessage
            id="FILTERS.LABEL.GENRE"
        />
            submitOnChange
        />
    </Col>
    <Col>
        <GenericSearch/>
    </Col>
</Row>;
