import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux";
import {CONTENT_CATEGORIES_REDUX_SECTION_NAME, CONTENT_POSTS_REDUX_SECTION_NAME, GAME_ROOMS_REDUX_SECTION_NAME, GROUPS_REDUX_SECTION_NAME, SCHOOLS_REDUX_SECTION_NAME, STUDENTS_REDUX_SECTION_NAME, SUPERVISORS_REDUX_SECTION_NAME} from "../app/modules/Application/_constants";
import {groupSlice, schoolSlice, studentsSlice, supervisorsSlice} from "../app/modules/Application/_redux";
import {contentCategorySlice} from "../app/modules/Application/_redux/contentCategory/contentCategorySlice";
import {contentPostSlice} from "../app/modules/Application/_redux/contentPost/contentPostSlice";
import {gameRoomSlice} from "../app/modules/Application/_redux/gameRoom/gameRoomSlice";
import {roomReducer} from "./rooms/reducer";
import {visitSlice} from "../app/modules/Application/_redux/visit/visitSlice";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    visit: visitSlice.slice.reducer,
    [SCHOOLS_REDUX_SECTION_NAME]: schoolSlice.slice.reducer,
    [SUPERVISORS_REDUX_SECTION_NAME]: supervisorsSlice.slice.reducer,
    [STUDENTS_REDUX_SECTION_NAME]: studentsSlice.slice.reducer,
    [GROUPS_REDUX_SECTION_NAME]: groupSlice.slice.reducer,
    [CONTENT_CATEGORIES_REDUX_SECTION_NAME]: contentCategorySlice.slice.reducer,
    [CONTENT_POSTS_REDUX_SECTION_NAME]: contentPostSlice.slice.reducer,
    [GAME_ROOMS_REDUX_SECTION_NAME]: gameRoomSlice.slice.reducer,

    rooms: roomReducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
