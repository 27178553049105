import {quickSlice} from "../../../../setup/redux/slice/quickSlice";
import {CONTENT_CATEGORIES_ENDPOINT, CONTENT_CATEGORIES_REDUX_SECTION_NAME} from "../../_constants";

import {map} from 'lodash';
import {multiPartContentType} from "../utils";

export const contentCategorySlice = quickSlice({
    name: CONTENT_CATEGORIES_REDUX_SECTION_NAME,
    endpoint: CONTENT_CATEGORIES_ENDPOINT,
    additionalHeaders: {
        onCreate: multiPartContentType,
        onUpdate: multiPartContentType,
    },
    dataTransform: (data) => {
        const formData = new FormData();
        map(data, (el, key) => {



            formData.append(key, el);
        });
        return formData;
    }
})
