import {useSetRecoilState} from "recoil";
import {posAnalyticsAtom} from "../../../Application/pages/GameRoom/charts/AnswerAnalytics";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import {getIcon} from "../../../../setup/utils/icons";
import React from "react";

export const ChartButton = ({handleClick, label="APPLICATION.COMMON.ACTIONS.ANALYTICS"}) => {


    return <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">
            <FormattedMessage id={label}/>
        </Tooltip>}
    >
        <Button
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={handleClick}
        >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                  src={toAbsoluteUrl(getIcon.from.Shopping('Chart-bar2'))}
              />
            </span>
        </Button>
    </OverlayTrigger>
}
