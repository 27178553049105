import axios from "axios";
import {BASE_API} from "../../../../_the_law/constants";
import {basedApiMock} from "../../../../_the_law/_helpers/forApiMock";

export const LOGIN_URL = basedApiMock("auth/login", "login");
export const REGISTER_URL = basedApiMock("schoolSignup", "schoolSignup");
export const REQUEST_PASSWORD_URL = basedApiMock("auth/forgot-password", "forgot-password");

export const ME_URL = `${BASE_API}/me`;

export function login(email, password) {
    return axios.post(LOGIN_URL, {email, password});
}

export function register(
    {
        name,
        first_name,
        last_name,
        email,
        password,
        password_confirmation,
        address,
        state
    }
) {
    return axios.post(REGISTER_URL,
        {
            name,
            first_name,
            last_name,
            email,
            password,
            password_confirmation,
            address,
            state
        }
    );
}

export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, {email});
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}
