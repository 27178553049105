import {createSlice} from "@reduxjs/toolkit";
import {updateEntities} from "./quickSlice";

const initialResourcesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    resourceForEdit: undefined,
    lastError: null,
    resourceCreated: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const resourcesSliceFactory = (name, {
    additionalReducers,
    additionalState = {},
    onResourcesFetched = (state, action) => {
    },
    onResourcesCreated = (state, action) => {
    }
} = {additionalReducers: {}}) => createSlice({
    name,
    initialState: {
        ...initialResourcesState,
        ...additionalState
    },
    reducers: {
        ...additionalReducers,
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getResourceById
        resourceFetched: (state, action) => {
            state.actionsLoading = false;
            state.resourceForEdit = action.payload.resourceForEdit;
            state.error = null;
        },
        // findResources
        resourcesFetched: (state, action) => {


            const {totalCount, entities} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;

            onResourcesFetched(state, action)
        },
        // createResource
        resourceCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.resource);
            state.resourceCreated = action.payload.resource.id;

            onResourcesCreated(state, action);
        },
        // updateResource
        resourceUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            updateEntities(state, action.payload.resource);
        },
        // deleteResource
        resourceDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(el => el.id !== action.payload.id);
        },
        // deleteResources
        resourcesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            );
        },
        // resourcesUpdateState
        resourcesStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const {ids, status, prop = 'status'} = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity[prop] = status;
                }
                return entity;
            });
        }
    }
});
