import {FormattedMessage} from "react-intl";
import {sortCaret} from "../../../../../_metronic/_helpers";
import {TagsFormatter} from "./partials";
import * as columnFormatters from "../../../CrudFormPage/table/column-formatters";
import React, {Fragment} from "react";
import {HighlightedTextSearch} from "../../partials";


export const columns = [
    {
        dataField: 'id',
        text: <FormattedMessage
            id="APPLICATION.COMMON.ID"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: 'name',
        text: <FormattedMessage
            id="APPLICATION.SCHOOL.REFERER"
        />,
        sort: true,
        sortCaret,
        formatter: (data, row) => {
            return <Fragment>
                <small>
                    <HighlightedTextSearch
                        text={data}
                    />
                </small>
                <div>
                    <HighlightedTextSearch text={`${row?.referer?.first_name} ${row?.referer?.last_name}`}/>
                </div>
            </Fragment>
                ;
        }
    },
    {
        dataField: 'tags',
        text: <FormattedMessage
            id="APPLICATION.COMMON.TAGS"
        />,
        sort: true,
        formatter: TagsFormatter
    },
    {
        dataField: "action",
        text: <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.COLUMNS.ACTIONS"/>,
        formatter: columnFormatters.ActionsColumnFormatter,
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    },
];
