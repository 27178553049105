import React, {useState} from "react";
import {useFormik} from "formik";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import * as Yup from "yup";
import {FormattedMessage, injectIntl, useIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {requestPassword} from "../_redux/authCrud";
import {Container, Form} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

const initialValues = {
    email: "",
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            width: '100%',
        }
    },
    content: {
        flex: '1 0 auto',
        alignSelf: 'center',
        width: '100%'
    },
    cover: {
        display: 'none',
        width: '100%',
        borderRadius: '3px 0 0 3px',
        [theme.breakpoints.up("sm")]: {
            display: 'unset',
        }
    },
    cover_xs: {
        display: 'none',
        width: '100%',
        borderRadius: '3px 3px 0 0',
        [theme.breakpoints.down("xs")]: {
            display: 'unset',
        }
    },
    controls: {
        display: 'flex',
        alignSelf: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    input: {
        borderRadius: 50,
        width: '100%',
        paddingRight: 10
    },
    button: {
        borderRadius: 50
    }
}));


function ForgotPassword(props) {
    const classes = useStyles();
    const intl = useIntl();
    const [isRequested, setIsRequested] = useState(false);
    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            requestPassword(values.email)
                .then(() => setIsRequested(true))
                .catch(() => {
                    setIsRequested(false);
                    setSubmitting(false);
                    setStatus(
                        intl.formatMessage(
                            {id: "AUTH.VALIDATION.NOT_FOUND"},
                            {name: values.email}
                        )
                    );
                });
        },
    });


    return <Container>
        <Grid container alignItems={"center"} justify={"center"}>
            <Grid item>
                <img
                    style={{
                        height: 300
                    }}
                    src="/media/logos/selyup_logo_forgot_password.jpg"
                    alt={`Welcome to ${process.env.REACT_APP_NAME}`}
                />
            </Grid>
            <Grid item xs={12} md={5}>
                <>
                    {isRequested && <Redirect to="/auth"/>}
                    {!isRequested && (
                        <div className="login-form login-forgot" style={{display: "block"}}>
                            <div className="text-center mb-10 mb-lg-20">
                                <h3 className="font-size-h1"><FormattedMessage
                                    id="AUTH.FORGOTTEN_PASSWORD"
                                /></h3>
                                <div className="text-muted font-weight-bold">
                                    <FormattedMessage
                                        id="AUTH.FORGOTTEN_PASSWORD.INSTRUCTIONS"
                                    />
                                </div>
                            </div>
                            <form
                                onSubmit={formik.handleSubmit}
                                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                            >
                                {formik.status && (
                                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                            {formik.status}
                                        </div>
                                    </div>
                                )}
                                <div className="form-group fv-plugins-icon-container">
                                    <Form.Control
                                        type="email"
                                        className={`h-auto py-5 px-6 ${getInputClasses(
                                            "email"
                                        )} ${classes.input}`}
                                        placeholder={intl.formatMessage({id: 'AUTH_FORGOTTEN_PASSWORD.EMAIL_ADDRESS'})}
                                        name="email"
                                        {...formik.getFieldProps("email")}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.email}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group d-flex flex-wrap flex-center">
                                    <button
                                        id="kt_login_forgot_submit"
                                        type="submit"
                                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 ${classes.button}`}
                                        disabled={formik.isSubmitting}
                                    >
                                        <FormattedMessage
                                            id="AUTH_FORGOTTEN_PASSWORD.SUBMIT"
                                        />
                                    </button>
                                    <Link to="/auth">
                                        <button
                                            type="button"
                                            id="kt_login_forgot_cancel"
                                            className={`btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4 ${classes.button}`}
                                        >
                                            <FormattedMessage
                                                id="AUTH_FORGOTTEN_PASSWORD.CANCEL"
                                            />
                                        </button>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    )}
                </>

            </Grid>
        </Grid>
    </Container>;
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
