import {quickSlice} from "../../../../setup/redux/slice/quickSlice";
import {GROUPS_ENDPOINT, GROUPS_REDUX_SECTION_NAME} from "../../_constants";

export const groupSlice = quickSlice({
    name: GROUPS_REDUX_SECTION_NAME,
    endpoint: GROUPS_ENDPOINT,
    onSliceEvents: {
        additionalState: {
            meta: {
                supervisors: [],
                students: []
            }
        },
        onResourcesFetched(state,action){
            state.meta.supervisors = action?.payload?.meta?.supervisors ?? [];
            state.meta.students = action?.payload?.meta?.students ?? [];
        }
    }
})
