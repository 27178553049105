import {GROUPS_REDUX_SECTION_NAME} from "../../_constants";
import {GROUP_LIST_URL} from "../../constants";
import {Filter} from "../../../partials/Filter";
import {Edit} from "../../../partials/Edit";
import {filterFields} from "./fields/filter";
import {fields} from "./fields/table";
import {GroupTable} from "./partials/Table";
import {groupSlice} from "../../_redux";
import {FormattedMessage} from "react-intl";
import React from "react";

export const groupsPageConfigs = {
    storeSection: GROUPS_REDUX_SECTION_NAME,
    actions: groupSlice.actions,
    base_url: GROUP_LIST_URL,
    listCmp: GroupTable,
    filterCmp: Filter,
    filterProps: {
        initValues: {
            search: '',
            supervisor: '',
            student: '',
        },
        fields: filterFields
    },
    newCmp: Edit,
    editCmp: Edit,
    formProps: {
        initValues: {
            name: "",
            description: "",
            supervisor: "",
            students: []
        },
        fields: fields
    },
    alias: {
        newTitle: <FormattedMessage
            id="PAGES.TITLE.NEW_GROUP"
        />,
        editTitle: <FormattedMessage
            id="PAGES.TITLE.EDIT_GROUP"
        />,
        listTitle: <FormattedMessage
            id="PAGES.TITLE.GROUPS_LIST"
        />
    },
    filter_set: {
        sortField: "id",
    },
    cardActions: [],
    rules: {
        canCreate: []
    }

};
