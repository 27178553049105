import {useSelector} from "react-redux";
import {checkIsActive} from "../_metronic/_helpers";
import {useHistory, useLocation} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {
    deleteAllResourceUrl,
    deleteResourceUrl,
    editAllResourceUrl,
    editResourceUrl,
    fetchResourceUrl, newResourceUrl
} from "../app/modules/Helpers";

export const useAuthUser = () => {
    const {user} = useSelector(state => state.auth);

    return user;
}

export const useAuthToken = () => {
    const {authToken} = useSelector(state => state.auth);

    return authToken;
}

export const useReLoginFor = (callable, condition = () => true) => {
    const needReLogin = useSelector(state => state.auth.needReLogin);

    useEffect(() => {
        if (condition(needReLogin)) {
            callable()
        }
    }, [needReLogin]);
}

export const useReLogin = () => {
    return  useSelector(state => state.auth.needReLogin);
};

export const useMenuItemUrl = (url) => {
    const location = useLocation();
    return useCallback((url) => {
        return checkIsActive(location, url)
            ? " menu-item-active menu-item-open "
            : "";
    }, [location]);
}

export const useUiEvents = (url) => {

    const history = useHistory();

    return {
        newResourceButtonClick: () => {
            history.push(newResourceUrl(url));
        },
        openEditResourceDialog: (id) => {
            history.push(editResourceUrl(url, id));
        },
        openDeleteResourceDialog: (id) => {
            history.push(deleteResourceUrl(url, id));
        },
        openDeleteResourcesDialog: () => {
            history.push(deleteAllResourceUrl(url));
        },
        openFetchResourcesDialog: () => {
            history.push(fetchResourceUrl(url));
        },
        openUpdateResourcesStatusDialog: () => {
            history.push(editAllResourceUrl(url));
        },
        back() {
            history.push(url)
        }
    }
}

export const useBackUrl = url => {
    let history = useHistory();
    return () => history.push(url);
}
