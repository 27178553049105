import React from "react";
import SelyLogin from "./SelyLogin";
import {Redirect, Switch} from "react-router-dom";
import {ContentRoute} from "../../../../_metronic/layout";
import ForgotPassword from "./ForgotPassword";
import SelyRegistration from "./SelyRegistration";
import {Container, Navbar} from "react-bootstrap";

const SelyAuthPage = (props) => {

    return <div
        style={{
            overflow: "auto",
            position: "fixed",
            width: '100%',
            height: '100%',
            backgroundColor: "white",
            top: 0,
            left: 0
        }}
    >

        <div style={{
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
        }}>
            <Navbar bg="primary">
                <Container>
                    <Navbar.Brand>
                        <img src="/media/logos/application_logo_b.png" style={{width: 50}} alt="logo"/>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </div>
        <div style={{
            position: 'absolute',
            height: 'calc( 100% - 100px )',
            top: 100,
            left: 0,
            width: '100%'
        }}>
            <Switch>
                <ContentRoute path="/auth/login" component={SelyLogin}/>
                <ContentRoute path="/auth/registration" component={SelyRegistration}/>
                <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login"/>
                <Redirect to="/auth/login"/>
            </Switch>
        </div>
    </div>


}

export default SelyAuthPage;
