import {actions} from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {

    axios.interceptors.response.use(value => value, error => {
        if (401 === error.response.status) {
            store.dispatch(actions.need_re_login(error));
            // swal({
            //     title: "Session Expired",
            //     text: "Your session has expired. Would you like to be redirected to the login page?",
            //     type: "warning",
            //     showCancelButton: true,
            //     confirmButtonColor: "#DD6B55",
            //     confirmButtonText: "Yes",
            //     closeOnConfirm: false
            // }, function(){
            //     window.location = '/login';
            // });
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    })

    axios.interceptors.request.use(
        config => {

            const {
                auth: {authToken, user}
            } = store.getState();

            if (authToken) {

                config.headers = {
                    ...config.headers,
                    "Authorization": `Bearer ${authToken}`,
                    "Accept": "application/json",
                }

                // config.headers.Authorization = `Bearer ${authToken}`;
                // config.headers.Accept = `application/json`;
                config.user = user;
            }

            return config;
        },
        error => Promise.reject(error)
    );
}
