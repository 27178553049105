import {quickSlice} from "../../../../setup/redux/slice/quickSlice";
import {CONTENT_POSTS_ENDPOINT, CONTENT_POSTS_REDUX_SECTION_NAME} from "../../_constants";

import {map} from 'lodash';
import {multiPartContentType} from "../utils";
import {put, take} from "redux-saga/effects";
import {END, eventChannel} from 'redux-saga';

let emit;
export const progressChan = eventChannel(emitter => {
    emit = emitter;
    return () => {
    }
});

export const contentPostSlice = quickSlice({
    name: CONTENT_POSTS_REDUX_SECTION_NAME,
    endpoint: CONTENT_POSTS_ENDPOINT,
    additionalHeaders: {
        onCreate: multiPartContentType,
        onUpdate: multiPartContentType,
    },
    dataTransform: (data) => {
        const formData = new FormData();
        map(data, (el, key) => {
            formData.append(key, el);
        });
        return formData;
    },
    additionalConfigs: {
        onCreate: {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                emit(percentCompleted);
                if (percentCompleted === 100) {
                    emit(END);
                } else {
                    emit(percentCompleted);
                }
            }
        },
        onUpdate: {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                emit(percentCompleted);
                if (percentCompleted === 100) {
                    emit(END);
                } else {
                    emit(percentCompleted);
                }
            }
        },
    },
    onActionEvents: {
        additionalActions: (requests, actions) => ({
            setProgress: ({progress}) => dispatch => {
                if (progress < 100) {
                    dispatch(actions.setMediaProgress({
                        mediaProgress: progress,
                        mediaUploading: true
                    }))
                } else {
                    dispatch(actions.setMediaProgress({
                        mediaProgress: 0,
                        mediaUploading: false
                    }))
                }
            }
        }),
    },
    onSliceEvents: {
        additionalState: {
            mediaProgress: 0,
            mediaUploading: false,
            meta: {
                categories: []
            }
        },
        additionalReducers: {
            setMediaProgress: (state, {payload: {mediaProgress = 0, mediaUploading = false}}) => {
                state.mediaProgress = mediaProgress;
                state.mediaUploading = mediaUploading;
            }
        },
        onResourcesFetched(state, action) {
            state.meta.categories = action?.payload?.meta?.categories ?? [];
        }
    }
})

export function* watchOnProgress(chan) {
    while (true) {
        const data = yield take(chan);
        yield put(contentPostSlice.actions.setProgress({progress: data}));
    }
}
