import {Form} from "react-bootstrap";
import React from "react";

export const SwitchAbilitation = (
    {
        label,
        id = label,
        checked = false,
        handleChange = () => {
        },
        disabled = false
    }
) => {
    return <Form.Check disabled={disabled} id={"switch-" + id} type={"switch"} onChange={handleChange}
                       checked={checked} label={label}/>;
}
