import {quickSlice} from "../../../../setup/redux/slice/quickSlice";
import {SUPERVISORS_ENDPOINT, SUPERVISORS_REDUX_SECTION_NAME} from "../../_constants";

export const supervisorsSlice = quickSlice({
    name: SUPERVISORS_REDUX_SECTION_NAME,
    endpoint: SUPERVISORS_ENDPOINT,
    onSliceEvents: {
        additionalState: {
            meta: {
                schools: []
            }
        },
        onResourcesFetched(state, action) {
            state.meta.schools = action.payload?.meta?.schools ?? [];
        }
    },
})
