import {FormattedMessage} from "react-intl";
import {sortCaret} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "../../../CrudFormPage/table/column-formatters";
import React from "react";

export const columns = [
    {
        dataField: 'id',
        text: <FormattedMessage
            id="APPLICATION.COMMON.ID"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: 'name',
        text: <FormattedMessage
            id="APPLICATION.COMMON.NAME"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: 'slug',
        text: <FormattedMessage
            id="APPLICATION.COMMON.SLUG"
        />,
        sort: true,
        sortCaret,
        formatter: (data) => {
            const href = process.env.REACT_APP_GAME_URL;
            return <a href={`${href}/${data}`} target="_blank">{data}</a>;
        }
    },
    {
        dataField: "action",
        text: <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.COLUMNS.ACTIONS"/>,
        formatter: columnFormatters.GameRoomActionsColumnFormatter,
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
        formatExtraData: {
            editAbilitations: () => {},
        }
    },
];
