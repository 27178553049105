import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {LoadingDialog as LoadingDialogControl} from "../../../../_metronic/_partials/controls";
import {useUIContext} from "../UIContext";
import {FormattedMessage} from "react-intl";

export function LoadingDialog() {
    const context = useUIContext();
    const {isLoading} = useSelector(
        state => ({isLoading: state[context.storeSection].listLoading}),
        shallowEqual
    );
    useEffect(() => {
    }, [isLoading]);
    return <LoadingDialogControl isLoading={isLoading} text={<FormattedMessage id="APPLICATION.COMMON.LOADING"/>}/>;
}
