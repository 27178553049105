import {useSelector} from "react-redux";

export const useRoomPresences = () => {
    const roomData = useSelector(state => state?.rooms ?? {});
    const presences = roomData.data?.members ?? {};
    const me = roomData?.data?.me ?? {id: -1};
    delete presences[me.id];
    const last_event = roomData?.event ?? '';

    return {
        presences,
        me,
        last_event,
        isPresent: (id) => !!presences[id] ?? false
    };
}
