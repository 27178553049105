import {NavLink, Link} from "react-router-dom";
import React, {memo} from "react";
import {useMenuItemUrl} from "../../../../../_the_law/hooks";

export const AsideSubMenuListItem = memo(({url, label, type='menu', target=null, classes= []}) => {
    const getMenuItemActive = useMenuItemUrl(url);

    console.log({classes})

    return <li
        className={`menu-item ${getMenuItemActive(url)}`}
        aria-haspopup="true"
    >
        {
            type === 'menu' && <NavLink className={`menu-link`} to={url}>
                <i className="menu-bullet menu-bullet-dot">
                    <span/>
                </i>
                <span className={`menu-text ${classes.join(" ")}`}>{label}</span>
            </NavLink>
        }
        {
            type === 'link' && <a className={`menu-link`} href={url} target={target}>
                <i className="menu-bullet menu-bullet-dot">
                    <span/>
                </i>
                <span className={`menu-text ${classes.join(" ")}`}>{label}</span>
            </a>
        }
    </li>
});
