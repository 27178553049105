import {useUIContext} from "../CrudFormPage/UIContext";
import Highlighter from "react-highlight-words";
import React from "react";

export const HighlightedTextSearch = ({text}) => {

    const {queryParams} = useUIContext();

    return <Highlighter
        highlightClassName="bg-warning p-0"
        searchWords={[queryParams?.filter?.search ?? '']}
        autoEscape={true}
        textToHighlight={text}
    />
}
