import {Col, Row} from "react-bootstrap";
import {MustBeAdmin, MustHaveRules, RULES} from "../../../../../../_the_law/_helpers/MustHaveRules";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../../../_metronic/_partials/controls";
import React from "react";
import {SupervisorSelect} from "../../partials/SupervisorSelect";
import {SwitchField} from "../../partials/Filter/fileds/Switch";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../../_metronic/_helpers";


export const fields = <Row className="inputs-rows mt-5">
    <MustHaveRules rules={[RULES.ADMIN, RULES.SCHOOL]}>
        <Col md={12}>
            <SupervisorSelect/>
        </Col>
    </MustHaveRules>
    {/*<Col md={6}>*/}
    {/*    <Field*/}
    {/*        label={<FormattedMessage*/}
    {/*            id="APPLICATION.COMMON.EMAIL"*/}
    {/*        />}*/}
    {/*        component={Input}*/}
    {/*        name="email"*/}
    {/*        key="email"*/}
    {/*    />*/}
    {/*</Col>*/}
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.STUDENT.NICKNAME"
            />}
            component={Input}
            name="nickname"
            key="nickname"
        />
    </Col>
    <Col md={6}/>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.STUDENT.FIRST_NAME"
            />}
            component={Input}
            name="student.first_name"
            key="student.first_name"
        />
    </Col>
    <Col md={6}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.STUDENT.LAST_NAME"
            />}
            component={Input}
            name="student.last_name"
            key="student.last_name"
        />
    </Col>
    <Col md={6}>
        <label><FormattedMessage
            id="INPUT.LABEL.BIRTH_DATE"
        /></label>
        <br/>
        <Field name="student.birth_date">
            {
                props => <input value={props.field.value} type="date" className="form-control" onChange={(e) => {
                    props.form.setFieldValue(props.field.name, e.target.value)
                }}/>
            }
        </Field>
    </Col>
    <Col md={6}>
        <label><FormattedMessage
            id="FILTERS.LABEL.GENRE"
        /></label>
        <br/>
        <SwitchField
            options={[
                {
                    label: <span> Male</span>,
                    value: "m",
                },
                {
                    label: <span> Female</span>,
                    value: "f",
                },
                {
                    label: <span> Unspecified</span>,
                    value: "n",
                },
            ]}
            name="student.genre"
            label=<FormattedMessage
            id="FILTERS.LABEL.GENRE"
        />
        />
    </Col>
</Row>;
