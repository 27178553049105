import {Button, ButtonGroup} from "react-bootstrap";
import React from "react";
import {Field} from "formik";

export const SwitchField = ({options = [], onSelected = "#1BC5BD", name, label = '', submitOnChange = false}) => {
    return <Field name={name} label={label}>
        {
            ({form, field}) => <ButtonGroup aria-label="switch">
                {
                    options.map(el => <Button
                        variant={"light"}
                        style={{backgroundColor: (el.value === field.value ? (el.onSelected ?? onSelected) : "#F3F6F9")}}
                        onClick={() => {
                            form.setFieldValue(field.name, el.value);
                            submitOnChange && form.submitForm();
                        }}
                    >{el.label}</Button>)
                }
            </ButtonGroup>
        }
    </Field>;
}
