import {callTypes} from "./resourcesSlice";


export const fetchResourcesFactory = ({findResources}, actions, callback) => queryParams => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return findResources(queryParams)
        .then(response => {
            const {meta, data: entities} = response.data;
            const {total: totalCount} = meta;
            callback(response.data, dispatch);
            return dispatch(actions.resourcesFetched({totalCount, entities, meta}));
        })
        .catch(error => {
            error.clientMessage = "Can't find resources";
            return dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};

export const fetchResourceFactory = ({getResourceById}, actions) => id => dispatch => {
    if (!id) {
        return dispatch(actions.resourceFetched({resourceForEdit: {}}));
    }

    dispatch(actions.startCall({callType: callTypes.action}));
    return getResourceById(id)
        .then(response => {
            const resource = response.data;
            return dispatch(actions.resourceFetched({resourceForEdit: resource}));
        })
        .catch(error => {
            error.clientMessage = "Can't find resource";
            return dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const deleteResourceFactory = ({deleteResource}, actions, hooks = []) => id => dispatch => {
    hooks.map(hook => {
        typeof hook === "function" && hook({type: 'delete', id})
    })
    dispatch(actions.startCall({callType: callTypes.action}));
    return deleteResource(id)
        .then(response => {
            return dispatch(actions.resourceDeleted({id}));
        })
        .catch(error => {
            error.clientMessage = "Can't delete resource";
            return dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const createResourceFactory = ({createResource}, actions, onCreateResource) => resourceForCreation => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return createResource(resourceForCreation)
        .then(response => {
            const resource = response.data;

            onCreateResource({
                resource,
                dispatch
            })

            return dispatch(actions.resourceCreated({resource}));
        })
        .catch(error => {
            error.clientMessage = "Can't create resource";
            return dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const updateResourceFactory = ({updateResource}, actions) => resource => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return updateResource(resource)
        .then((response) => {

            return dispatch(actions.resourceUpdated({resource: response.data}));
        })
        .catch(error => {
            error.clientMessage = "Can't update resource";
            return dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const updateResourcesStatusFactory = ({updateStatusForResources}, actions) => (ids, status, prop = 'status') => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return updateStatusForResources(ids, status)
        .then(() => {
            return dispatch(actions.resourcesStatusUpdated({ids, status, prop}));
        })
        .catch(error => {
            error.clientMessage = "Can't update resources status";
            return dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const deleteResourcesFactory = ({deleteResources}, actions) => ids => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return deleteResources(ids)
        .then(() => {
            return dispatch(actions.resourcesDeleted({ids}));
        })
        .catch(error => {
            error.clientMessage = "Can't delete resources";
            return dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const resourcesCrudActionsFactory = (requests, actions, {
    onDelete = [],
    onResourcesFetch = () => {
    },
    onCreateResource = () => {
    },
    additionalActions = (requests, actions) => ({})
} = {
    onDelete: [], onResourcesFetch: () => {
    }
}) => ({
    fetchResources: fetchResourcesFactory(requests, actions, onResourcesFetch),
    fetchResource: fetchResourceFactory(requests, actions),
    deleteResource: deleteResourceFactory(requests, actions, onDelete),
    createResource: createResourceFactory(requests, actions, onCreateResource),
    updateResource: updateResourceFactory(requests, actions),
    updateResourcesStatus: updateResourcesStatusFactory(requests, actions),
    deleteResources: deleteResourcesFactory(requests, actions),
    ...additionalActions(requests, actions)
});
