import React from "react";

export const newResourceUrl = (resourceUrl) => `${resourceUrl}/new`;
export const editResourceUrl = (resourceUrl, id = ':id') => `${resourceUrl}/${id}/edit`;
export const deleteResourceUrl = (resourceUrl, id = ':id') => `${resourceUrl}/${id}/delete`;
export const editAllResourceUrl = (resourceUrl) => `${resourceUrl}/updateAll`;
export const deleteAllResourceUrl = (resourceUrl) => `${resourceUrl}/deleteAll`;
export const fetchResourceUrl = (resourceUrl) => `${resourceUrl}/fetch`;
export const listResourceUrl = (resourceUrl) => `${resourceUrl}/list`;
const {PUBLIC_URL} = process.env;

export const Icons = {
    Frozen: (props) => <img {...props} src={`${PUBLIC_URL}/media/icons/frozen.svg`} width="16" height="16" alt="frozen"/>,
    Dry: (props) => <img {...props} src={`${PUBLIC_URL}/media/icons/dry.svg`} width="16" height="16" alt="frozen"/>,
    Perishable: (props) => <img {...props} src={`${PUBLIC_URL}/media/icons/perishable.svg`} width="16" height="16" alt="frozen"/>,
}
