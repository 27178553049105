import {useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import Login from "../Auth/pages/Login";
import React from "react";

export const InlineLogin = () => {

    const needReLogin = useSelector(state => state.auth.needReLogin);

    const handleClose = () => {
        // setShow(false);
    };

    return (
        <>
            <Modal show={needReLogin} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Effettua Login</Modal.Title>
                </Modal.Header>
                <Modal.Body><Login isReLogin/></Modal.Body>
            </Modal>
        </>
    );
}
