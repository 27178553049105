import {Col, Row} from "react-bootstrap";
import {SchoolSelect} from "../../partials/SchoolSelect";
import {GenericSearch} from "../../partials/Filter/GenericSearch";
import React from "react";
import {SupervisorSelect} from "../../partials/SupervisorSelect";
import {StudentSelect} from "../../partials/StudentSelect";
import {FormattedMessage} from "react-intl";
import {AsOption} from "../../../../partials/FormattedMessages";

export const filterFields = <Row>
    <Col md={3}>
        <StudentSelect
            emptyOption={<AsOption value=""><FormattedMessage
                id="FILTERS.EMPTY_OPTION.ALL_STUDENTS"
            /></AsOption>}
            submitOnChange={true}
            metaSection="groups"
        />
    </Col>
    <Col md={3}>
        <SupervisorSelect
            emptyOption={<AsOption value=""><FormattedMessage
                id="FILTERS.EMPTY_OPTION.ALL_SUPERVISORS"
            /></AsOption>}
            submitOnChange={true}
            metaSection="groups"
        />
    </Col>
    <Col>
        <GenericSearch/>
    </Col>
</Row>;
