import {useSelector} from "react-redux";
import React from "react";

export const SupervisorName = ({id, metaSection = "groups"}) => {
    const supervisors = useSelector(state => state?.[metaSection]?.meta?.supervisors ?? []);

    const name = supervisors.filter(el => el.id === id)?.[0]?.name ?? '';

    return <span>{name}</span>
}
