import {TableList} from "../../../../partials/Table";
import {FormattedMessage} from "react-intl";
import React from "react";
import {HighlightedTextSearch} from "../../../partials";
import {Badge} from "react-bootstrap";
import {ActionsColumn} from "../../../../CrudFormPage/table/column-formatters/ActionsColumnFormatter";
import {editResourceUrl} from "../../../../Helpers";
import {STUDENT_LIST_URL} from "../../../constants";
import {ChartButton} from "../../../../CrudFormPage/table/partials/ChartButton";
import {useHistory} from "react-router";


export const ProfilerBtn = ({row}) => {
    const history = useHistory();

    return <ChartButton label="Profiler" handleClick={() => {
        return history.push(`${editResourceUrl(STUDENT_LIST_URL, row.id)}/profiler`);
    }}/>
}

export const StudentTable = () => <TableList columns={[
    {
        dataField: 'id',
        text: <FormattedMessage
            id="APPLICATION.COMMON.ID"
        />,
    },
    {
        dataField: 'student.first_name',
        text: <FormattedMessage
            id="APPLICATION.SCHOOL.NAME"
        />,
        formatter: (val, row) => <HighlightedTextSearch
            text={`${row.student.last_name} ${row.student.first_name}`}
        />
    },
    {
        dataField: 'student.genre',
        text: <FormattedMessage
            id="APPLICATION.SCHOOL.GENRE"
        />,
        formatter: (val, row) => {
            return {
                m: <Badge variant={"info"}> Male</Badge>,
                f: <Badge variant={"info"}> Female</Badge>,
                n: <Badge variant={"info"}> Unspecified</Badge>
            }[val]
        }
    },
    {
        dataField: "action",
        text: <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.COLUMNS.ACTIONS"/>,
        formatter: (
            cellContent,
            row,
            rowIndex,
        ) => {


            return <ActionsColumn prepend={[<ProfilerBtn row={row}/>]} row={row}/>
        },
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    },
]}/>
