import React, {useMemo} from "react";
import {useUIContext} from "../UIContext";

export function Grouping() {
    // Products UI Context
    const {storeSection, ...context} = useUIContext();
    const uiProps = useMemo(() => {
        return {
            ids: context.ids,
            setIds: context.setIds,
            deleteMultipleResource: context.deleteMultipleResource,
            fetchResource: context.fetchResource,
            updateStatusResource: context.updateStatusResource
        };
    }, [context]);

    return (uiProps.ids.length > 0 && (
        <div className="form">
            <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
                <div className="col-xl-12">
                    <div className="form-group form-group-inline">
                        <div className="form-label form-label-no-wrap">
                            <label className="-font-bold font-danger-">
                <span>
                  Selected records count: <b>{uiProps.ids.length}</b>
                </span>
                            </label>
                        </div>
                        <div>
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className="btn btn-danger font-weight-bolder font-size-sm"*/}
                            {/*    onClick={uiProps.deleteMultipleResource}*/}
                            {/*>*/}
                            {/*    <i className="fa fa-trash"/> Delete All*/}
                            {/*</button>*/}
                            {/*&nbsp;*/}
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className="btn btn-light-primary font-weight-bolder font-size-sm"*/}
                            {/*    onClick={uiProps.fetchResource}*/}
                            {/*>*/}
                            {/*    <i className="fa fa-stream"/> Fetch Selected*/}
                            {/*</button>*/}
                            {/*&nbsp;*/}
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className="btn btn-light-primary font-weight-bolder font-size-sm"*/}
                            {/*    onClick={uiProps.updateStatusResource}*/}
                            {/*>*/}
                            {/*    <i className="fa fa-sync-alt"/> Update Status*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )) || null;
}
