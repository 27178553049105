import {createAction, createAsyncThunk, createReducer} from "@reduxjs/toolkit";
import Axios from "axios";
import {GAME_ROOMS_ENDPOINT} from "../../app/modules/Application/_constants";


export const ROOM_SUBSCRIBED = createAction("ROOM_SUBSCRIBED");
export const MEMBERS_CHANGED = createAction("MEMBERS_CHANGED");
export const GAME_STARTED = createAction("GAME_STARTED");
export const GAME_PAUSED = createAction("GAME_PAUSED");
export const GAME_EVENT = createAction("GAME_EVENT");

export const PROFILE_STUDENT = createAsyncThunk("PROFILE_STUDENT",({game_room,student}, thunkAPI) => {
    return Axios.get(`${GAME_ROOMS_ENDPOINT}/${game_room}/profiler/${student}`).then(response => {
        return response.data;
    }).catch(reason => thunkAPI.rejectWithValue(reason));
})

const initialState = {
    channel: '',
    data: {},
    event: '',
    eventData: {},
    generatedEvent: '',
    started: false,
    profiling: null,
};


export const roomReducer = createReducer(initialState, builder => {
    builder.addCase(PROFILE_STUDENT.fulfilled, (state, action) => {
        return {
            ...state,
            profiling: action.payload,
        }
    })
    builder.addCase(ROOM_SUBSCRIBED, (state, action) => {
        return {
            ...action,
            started: (action?.data?.me?.info?.tags ?? []).indexOf("started") >= 0
        };
    })
    builder.addCase(MEMBERS_CHANGED, (state, action) => {
        return {
            ...state,
            event: action.event
        };
    })
    builder.addCase(GAME_EVENT, (state, action) => {
        return {
            ...state,
            event: action?.event,
            eventData: action.data,
            generatedEvent: action?.data?.event?.messageType
        };
    })
    builder.addCase(GAME_STARTED, (state, action) => {

        return {
            ...state,
            started: true
        };
    })
    builder.addCase(GAME_PAUSED, (state, action) => {
        return {
            ...state,
            started: false
        };
    })
})
