import {Form} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React from "react";
import {useSelector} from "react-redux";
import {AsOption} from "../../../partials/FormattedMessages";
import {useUIContext} from "../../../CrudFormPage/UIContext";

export const ContentCategoriesSelection = ({
                                               value,
                                               handleChange
                                           }) => {

    const {storeSection} = useUIContext();

    const categories = useSelector(state => state?.[storeSection]?.meta?.categories);

    return <Form.Group>
        <Form.Control as="select" value={value} onChange={e => handleChange(e.target.value)}>
            <AsOption>
                <FormattedMessage
                    id="APPLICATION.COMMON.SELECT_CATEGORY"
                />
            </AsOption>
            {
                categories?.map(el => <option value={el.id}>{el.title}</option>)
            }
        </Form.Control>
    </Form.Group>
}
