import React from "react";
import {LoadingDialog} from "./loading-dialog/LoadingDialog";
import {Card} from "./Card";
import {UIProvider} from "./UIContext";
import ManagerRoutes from "./ManagerRoutes";
import {ContentRoute} from "../../../_metronic/layout";
import {SingleDeleteDialog} from "./delete-single-dialog/SingleDeleteDialog";
import {MultipleDeleteDialog} from "./delete-multiple-dialog/MultipleDeleteDialog";
import {initialFilter} from "./UIHelpers";
import {editResourceUrl, newResourceUrl} from "../Helpers";


export function Page(
    {
        base_url,
        actions,
        storeSection,
        listCmp,
        newCmp,
        editCmp = newCmp,
        loadingCmp = LoadingDialog,
        multipleDeleteCmp = MultipleDeleteDialog,
        singleDeleteCmp = SingleDeleteDialog,
        filterCmp = null,
        alias = {},
        cardActions = [],
        filter_set = {},
        formProps = {initValues:{},fields:[]},
        filterProps = {initValues:{},fields:[]},
        rules = {
            canCreate: [],
            canEdit: [],
            canDelete: [],
        },
        additionalRoutes = []
    }
) {

    return (
        <UIProvider filterProps={filterProps} formProps={formProps} rules={rules} alias={alias} actions={actions} filters_set={{...initialFilter, ...filter_set}}
                    base_url={base_url} storeSection={storeSection}>
            {React.createElement(loadingCmp)}
            <ContentRoute exact path={editResourceUrl(base_url)} component={editCmp}/>
            <ContentRoute exact path={newResourceUrl(base_url)} component={newCmp}/>
            <ManagerRoutes {...{
                multipleDeleteCmp,
                singleDeleteCmp,
            }}/>
            <Card filterProps={filterProps} cardActions={cardActions} filterCmp={filterCmp} listCmp={listCmp}/>
            {
                additionalRoutes.map(el => <ContentRoute exact path={el.path} component={el.component} />)
            }
            {/*<ContentRoute exact path={base_url}>*/}
            {/*</ContentRoute>*/}
        </UIProvider>
    );
}
