import React, {useState} from "react";
import {Col, ListGroup, ListGroupItem, Modal, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Dialog, DialogContent, DialogTitle, Input, Paper, Typography} from "@material-ui/core";
import {capitalize} from "lodash";
import {GameResults} from "../../../../partials/GameResults";
import {useSelector} from "react-redux";


export const Profiler = (profProps) => {


    const id = profProps?.match?.params?.id;

    const student = useSelector(state => state?.students?.entities.filter(el => el?.id?.toString() === id?.toString())?.[0])



    const [dOpen, setDOpen] = useState(false);
    const [resultView, setResultView] = useState(null)

    const gamesUUID = Object.keys(student?.games ?? {});

    const getPointsFor = (gameEx, ns) => {
        const pointTotals = ns.reduce((a, n) => a + (gameEx?.[n]?.points ?? 0), 0);
        return pointTotals >= 30 ? 30 : pointTotals;
    };

    const games = gamesUUID.map(uuID => {
        let game = student?.games?.[uuID] ?? [];
        return ({
            evaluationResults: {
                overall: Math.ceil((game?.length ?? 0) * 100 / 14),
                recognition: Math.ceil(getPointsFor(game, [0, 3, 5, 8, 11]) * 100 / 30),
                reaction: Math.ceil(getPointsFor(game, [1, 4, 7, 10, 12]) * 100 / 30),
                empathy: Math.ceil(getPointsFor(game, [2, 6, 9, 13]) * 100 / 30),
            },
            hierarchy: [
                "Game Room",
                "cattle-5285",
                uuID
            ],
        });
    });

    const overall = {
        evaluationResults: {
            overall: 100,
            recognition: 100,
            reaction: 35,
            empathy: 76,
        },
        hierarchy: [
            "Student",
            "Overall",
        ],
    };

    return <Modal dialogClassName="xl-modal" show size="lg" onHide={() => profProps.history.goBack()}>
        <Modal.Header>
            <div><FormattedMessage
                id="PAGES.GAME_ROOM.PROFILE_TITLE"
            /> <strong>{student?.student?.first_name} {student?.student?.last_name}</strong></div>
        </Modal.Header>

        <Dialog open={dOpen} onClose={() => setDOpen(false)}>
            <DialogTitle><strong>{capitalize(resultView?.label ?? '')}</strong>: {resultView?.title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    {resultView?.body}
                </Typography>
            </DialogContent>
        </Dialog>

        <Modal.Body>
            <Paper>
                <ListGroup className="mb-5">
                    <ListGroupItem>
                        <GameResults
                            onCheck={result => {
                                setDOpen(true);
                                setResultView(result);
                            }}
                            {...overall}
                        />
                    </ListGroupItem>
                </ListGroup>
            </Paper>
            <Paper>

                <Row>
                    <Col xs={12}>
                        <Input style={{
                            padding: 15
                        }} fullWidth placeholder="Search for School/Game Room/Session"/>
                    </Col>
                </Row>

                <ListGroup>
                    {
                        games.map(el => <ListGroupItem>
                            <GameResults
                                onCheck={result => {
                                    setDOpen(true);
                                    setResultView(result);
                                }}
                                {...el}
                            />
                        </ListGroupItem>)
                    }
                </ListGroup>
            </Paper>
        </Modal.Body>
    </Modal>
}
