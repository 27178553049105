/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter, withRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {Routes} from "./Routes";
import {I18nProvider} from "../_metronic/i18n";
import {LayoutSplashScreen, MaterialThemeProvider} from "../_metronic/layout";
import "../assets/css/main.css";

const LogRouter = withRouter(({location}) => {
    return <input hidden/>
});

export default function App({store, persistor, basename}) {


    const CrossStorageHub = require('cross-storage').CrossStorageHub;

    CrossStorageHub.init([
        {origin: new RegExp(`${process.env.REACT_APP_CROSS_STORAGE_APP}$`), allow: ['get', 'set', 'del', 'getKeys', 'clear']},
        {origin: new RegExp(`${process.env.REACT_APP_CROSS_STORAGE_FRONT}$`), allow: ['get', 'set']}
    ]);


    return (
        /* Provide Redux store */
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen/>}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <BrowserRouter basename={basename}>
                        <>
                            <LogRouter/>
                            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                            <MaterialThemeProvider>
                                {/* Provide `react-intl` context synchronized with Redux state.  */}
                                <I18nProvider>
                                    {/* Render routes with provided `Layout`. */}
                                    <Routes/>
                                </I18nProvider>
                            </MaterialThemeProvider>
                        </>
                    </BrowserRouter>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
}
