import {useDispatch, useSelector} from "react-redux";
import {useUIContext} from "../../../CrudFormPage/UIContext";
import React, {useEffect, useState} from "react";
import {gameRoomSlice} from "../../_redux/gameRoom/gameRoomSlice";
import {Col, Modal, Row} from "react-bootstrap";
import {ApexChart} from "./charts/AnswerAnalytics";
import {AnswerTimeAnalytics} from "./charts/AnswerTimeAnalytics";
import {getGameRoomEvents} from "../../../../../redux/pusherStore";
import {ParticipantList} from "./Analytics/ParticipantList";
import Jumbotron from "react-bootstrap/Jumbotron";
import {useRoomPresences} from "../../hooks";
import {keys} from 'lodash';
import {FormattedMessage} from "react-intl";

export const GameRoomAnalytics = ({
                                      handleClose = null,
                                      match
                                  }) => {

    const {presences} = useRoomPresences();

    const {resourceForEdit} = useSelector(state => state.game_rooms);

    const id = match?.params?.id;
    const {back} = useUIContext();


    const [slug, setSlug] = useState(null);

    const dispatch = useDispatch();

    const roomData = useSelector(state => state?.rooms ?? {});

    const last_event = roomData?.event ?? '';
    const last_generated_event = roomData?.generatedEvent ?? '';


    function initResource() {
        dispatch(gameRoomSlice.actions.fetchResource(id));
    }

    useEffect(() => {
        initResource();
    }, [id])

    useEffect(() => {
        if (resourceForEdit) {
            setSlug(resourceForEdit.slug);
        }

    }, [resourceForEdit]);

    useEffect(() => {
        if (slug) {
            getGameRoomEvents(slug, "App\\Events\\GameRoomEventGenerated");
        }
    }, [slug])

    useEffect(() => {
        if (last_event === "App\\Events\\GameRoomEventGenerated" && (last_generated_event === 'GameStarted' || last_generated_event === 'GameEnded')) {
            initResource();
        }
    }, [last_event, last_generated_event])

    return <Modal dialogClassName="xl-modal" show={resourceForEdit} size="lg" onHide={handleClose ?? back}>
        <Modal.Body>
            <Row className="align-items-stretch">
                <Col md={6}>
                    <ParticipantList game_room={resourceForEdit}/>
                </Col>
                <Col md={6}>
                    <Row className="justify-content-center align-content-center h-100">
                        <Col xs={12}>
                            <Jumbotron className="text-center">
                                <h2><FormattedMessage
                                    id="PAGES.ANALYTICS.USERS_CONNECTED"
                                /></h2>
                                <h1>{keys(presences)?.length ?? 0}</h1>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className="my-2">
                    <ApexChart game_room={resourceForEdit}/>
                </Col>
                <Col md={6} className="my-2">
                    <AnswerTimeAnalytics game_room={resourceForEdit}/>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
}
