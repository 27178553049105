import {TableList} from "../../../../partials/Table";
import {FormattedMessage} from "react-intl";
import {sortCaret} from "../../../../../../_metronic/_helpers";
import React, {Fragment} from "react";
import * as columnFormatters from "../../../../CrudFormPage/table/column-formatters";

export const SupervisorTable = () => <TableList columns={[
    {
        dataField: 'id',
        text: <FormattedMessage
            id="APPLICATION.COMMON.ID"
        />,
        sort: true,
        sortCaret
    },
    {
        dataField: 'supervisor.first_name',
        text: <FormattedMessage
            id="APPLICATION.SCHOOL.NAME"
        />,
        sort: true,
        sortCaret,
        formatter: (val, row) => <Fragment>{row.supervisor.last_name} {row.supervisor.first_name}</Fragment>
    },
    {
        dataField: "action",
        text: <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.COLUMNS.ACTIONS"/>,
        formatter: columnFormatters.ActionsColumnFormatter,
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    },
]}/>
