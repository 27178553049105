import {identity, mapValues} from 'lodash';
import axios from "axios";

export const UPDATE_STATUS_FOR_RESOURCES = "updateStatusForResources";
export const DELETE_RESOURCES = "deleteResources";
export const FIND_RESOURCES = "find";
// CREATE =>  POST: add a new resource to the server
export const createResourceFactory = (base_url, additionalHeaders = {}, dataTransform = identity, additionalConfigs = {}) => (resource) => {

    return axios.post(base_url, dataTransform(resource), {
        headers: {
            ...additionalHeaders
        },
        ...additionalConfigs,
    });
}

// READ
export const getAllResourcesFactory = (base_url) => () => {
    return axios.get(base_url);
}

export const getResourceByIdFactory = (base_url) => (resourceId) => {
    return axios.get(`${base_url}/${resourceId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export const findResourcesFactory = (base_url, findUrl = FIND_RESOURCES) => (queryParams) => {
    return axios.get(`${base_url}${findUrl !== '' ? `/${findUrl}` : ''}`, {params: queryParams});
}

// UPDATE => PUT: update the resource on the server
export const updateResourceFactory = (base_url, additionalHeaders = {}, dataTransform = identity, additionalConfigs) => (resource) => {
    return axios.post(`${base_url}/${resource.id}`, dataTransform({
        ...resource,
        _method: "put"
    }), {
        headers: {
            ...additionalHeaders
        },
        ...additionalConfigs
    });
}

// UPDATE Status
export const updateStatusForResourcesFactory = (base_url, batchUpdateUrl = UPDATE_STATUS_FOR_RESOURCES) => (ids, status) => {
    return axios.post(`${base_url}/${batchUpdateUrl}`, {ids, status});
}

// DELETE => delete the resource from the server
export const deleteResourceFactory = (base_url) => (resourceId) => {
    return axios.delete(`${base_url}/${resourceId}`);
}

// DELETE Resources by ids
export const deleteResourcesFactory = (base_url, batchDeleteUrl = DELETE_RESOURCES) => (ids) => {
    return axios.post(`${base_url}/${batchDeleteUrl}`, {ids});
}

export const resourcesCrudFactory = (base_url, {
    findUrl = FIND_RESOURCES,
    batchUpdateUrl = UPDATE_STATUS_FOR_RESOURCES,
    batchDeleteUrl = DELETE_RESOURCES,
    additional_crud = {},
    additionalHeaders = {
        onCreate: {},
        onUpdate: {},
    },
    additionalConfigs = {
        onCreate: {},
        onUpdate: {},
    },
    dataTransform = identity
} = {
    findUrl: FIND_RESOURCES,
    batchUpdateUrl: UPDATE_STATUS_FOR_RESOURCES,
    batchDeleteUrl: DELETE_RESOURCES,
}) => ({
    createResource: createResourceFactory(base_url, additionalHeaders.onCreate, dataTransform, additionalConfigs.onCreate),
    getAllResources: getAllResourcesFactory(base_url),
    getResourceById: getResourceByIdFactory(base_url),
    findResources: findResourcesFactory(base_url, findUrl),
    updateResource: updateResourceFactory(base_url, additionalHeaders?.onUpdate, dataTransform, additionalConfigs.onUpdate),
    updateStatusForResources: updateStatusForResourcesFactory(base_url, batchUpdateUrl),
    deleteResource: deleteResourceFactory(base_url),
    deleteResources: deleteResourcesFactory(base_url, batchDeleteUrl),
    ...mapValues(additional_crud, el => el(base_url))
})
