import {useHistory} from "react-router-dom";
import {
    deleteAllResourceUrl,
    deleteResourceUrl,
    editAllResourceUrl,
    editResourceUrl,
    fetchResourceUrl, newResourceUrl
} from "../Helpers";

export const Typology = ["Virtuale", "Fisica"];
export const UmTypes = ["pz", "crt", 'kg'];
export const CatMercTypes = ["Gelo", "Secco", 'Deperibile'];
export const CssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{dataField: "id", order: "asc"}];
export const sizePerPageList = [
    {text: "3", value: 3},
    {text: "5", value: 5},
    {text: "10", value: 10}
];
export const initialFilter = {
    filter: {
    },
    sortOrder: "asc", // asc||desc
    sortField: "",
    page: 1,
    per_page: 10
};

export const useUiEvents = ({base_url}) => {
    const history = useHistory();

    return {
        back: () => history.push(base_url),
        newResource: () => {
            history.push(newResourceUrl(base_url));
        },
        editResource: (id) => {
            history.push(editResourceUrl(base_url, id));
        },
        deleteSingleResource: (id) => {
            history.push(deleteResourceUrl(base_url, id));
        },
        deleteMultipleResource: () => {
            history.push(deleteAllResourceUrl(base_url));
        },
        fetchResource: () => {
            history.push(fetchResourceUrl(base_url));
        },
        updateStatusResource: () => {
            history.push(editAllResourceUrl(base_url));
        },
    };
}
