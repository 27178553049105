export const baseIconsDir = "/media/svg/icons";

export const getIcon =  ({
    from: {
        Shopping: (icon,type='svg') => `${baseIconsDir}/Shopping/${icon}.${type}`,
        Communication: (icon,type='svg') => `${baseIconsDir}/Communication/${icon}.${type}`,
        Files: (icon,type='svg') => `${baseIconsDir}/Files/${icon}.${type}`,
        Media: (icon,type='svg') => `${baseIconsDir}/Media/${icon}.${type}`,
        Administration: (icon,type='svg') => `${baseIconsDir}/Navigation/${icon}.${type}`,
        Contents: (icon,type='svg') => `${baseIconsDir}/Navigation/${icon}.${type}`,
        Game: (icon,type='svg') => `${baseIconsDir}/Navigation/${icon}.${type}`,
        Students: (icon,type='svg') => `${baseIconsDir}/Navigation/${icon}.${type}`,
    }
})
