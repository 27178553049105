//region single route
import React from "react";
import {Route} from "react-router-dom";
import {identity} from 'lodash';

export const VerbRoute = ({
                              path,
                              withId = false,
                              exact = false,
                              children,
                              onHide = identity,
                              context,
                              ...props
                          }) => {

    const path1 = path(context.base_url);

    return <Route path={path1} exact={exact}>
        {
            ({match}) =>
                React.cloneElement(children, {
                    show: match != null,
                    onHide: () => {
                        onHide("back");
                        context.back()
                    },
                    id: withId && match && match.params.id,
                    ...props
                })
        }
    </Route>
}
//region compound component for routes
export const VerbRoutes = ({children, routeProps, context}) => {
    return React.Children.map(children, Cmp => {

        if (React.isValidElement(Cmp) && Cmp.props && Cmp.props.path) {
            return <VerbRoute
                path={Cmp.props.path}
                withId={Cmp.props.withId ?? false}
                onHide={Cmp.props.onHide ?? identity}
                exact={Cmp.props.exact ?? false}
                context={context}
                {...routeProps}
            >
                {Cmp}
            </VerbRoute>
        }

        throw new Error('all children passed must be valid elements and must be have "path" prop');
    })
}
