import * as moment from "moment";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const ParticipantStatusLine = ({
                                          participant: {id, name}, presences, events, onVisitStudent = () => {
    }
                                      }) => {

    if (id === 65) {
        console.log(events.sort((a, b) => {
            return !moment(a.created_at).isAfter(moment(b.created_at)) ? 1 : -1;
        }))
    }

    const isPresent = !!presences?.[id] ?? false;

    const {event = {}, student_id = -1} = useSelector(state => state?.rooms?.eventData ?? {});

    const [myLastEvent, setMyLastEvent] = useState(null);


    const gameEvents = {
        GameInstanceCreated: {
            title: (event) => "Ha avviato il gioco"
        },
        StartButtonClicked: {
            title: (event) => "Ha cominciato la partita"
        },
        GameButtonClicked: {
            title: (event) => `Ha selezionato lo stage ${event?.gameIndex + 1}`
        },
        GameStarted: {
            title: (event) => `Sta rispondendo alla domanda ${event?.gameIndex + 1}`
        },
        GameEnded: {
            title: (event) => `Ha risposto ${event.answerIndex + 1} alla domanda ${event?.gameIndex + 1}`
        },
    }

    useEffect(() => {

        const messageType = event?.messageType ?? '';
        const isGameEvent = !!gameEvents[messageType] || false;
        if (student_id === id && isGameEvent) {
            setMyLastEvent(event);
        }
    }, [student_id, event])

    return <Row className="justify-content-between">
        <Col md="auto">
            {name}
        </Col>
        <Col md="auto">
            <Row className="align-items-center">
                <Col xs={"auto"}>
                    {
                        isPresent &&
                        <Badge
                            variant={"info"}>{gameEvents[myLastEvent?.messageType]?.title(myLastEvent) ?? 'In attesa'}</Badge>
                    }
                </Col>
                <Col xs={"auto"}>
                    <Badge className="cursor-pointer" onClick={() => onVisitStudent(id)}
                           variant={`${isPresent ? 'success' : 'warning'}`}><i className="fa fa-eye text-light fa-sm"/></Badge>
                </Col>
            </Row>

        </Col>
    </Row>
}
