import {useUIContext} from "./UIContext";
import {VerbRoutes} from "../../../_the_law/_partials/_controls/VerbRoutes";
import React from "react";
import {deleteAllResourceUrl, deleteResourceUrl, fetchResourceUrl} from "../Helpers";

export default ({
                    multipleDeleteCmp,
                    singleDeleteCmp,
                }) => {

    const {actions, ...context} = useUIContext();

    return <VerbRoutes context={context} routeProps={{actions}}>
        {React.createElement(multipleDeleteCmp, {path: deleteAllResourceUrl, exact: true})}
        {React.createElement(singleDeleteCmp, {path: deleteResourceUrl, withId: true, exact: true})}
    </VerbRoutes>;
}
