import {useUIContext} from "../UIContext";
import React, {useMemo} from "react";
import {MustHaveRules} from "../../../../_the_law/_helpers/MustHaveRules";

export const NewResourceButton = () => {
    const context = useUIContext();
    const {newResource, rules} = useMemo(() => {
        return {newResource: context.newResource, rules: context.rules};
    }, [context]);

    return <MustHaveRules rules={rules.canCreate ?? false}>
        <button
            type="button"
            className="btn btn-primary"
            onClick={newResource}
        >
            {context.alias.newTitle}
        </button>
    </MustHaveRules>

}
