import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/layout";
import {HOME, PAGE_URL} from "./modules/Application/constants";
import {ModuleContainer} from "./modules/partials/ModuleContainer";
import ApplicationDashboardDefinition from "./modules/Application/pages/ApplicationDashboardDefinition";


const ApplicationDashboard = () => <ModuleContainer moduleDefinition={ApplicationDashboardDefinition}/>;

export default function BasePage() {
    // useEffect(() => {
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <Redirect exact from="/" to={HOME}/>
                <Route path={PAGE_URL} component={ApplicationDashboard}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
