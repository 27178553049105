import {Col, Row} from "react-bootstrap";
import {Field} from "formik";
import {FormattedMessage} from "react-intl";
import React, {Fragment} from "react";
import {Input} from "../../../../../_metronic/_partials/controls";
import v from "voca";
import {ImageHolder} from "../../partials/ImageHolder";

export const fields = <Row className="inputs-rows mt-5">
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.CONTENT_CATEGORY.TITLE"
            />}
            component={({field, form, ...props}) => <Fragment>
                <Input form={form} field={field} onChange={e => {

                    form.setFieldValue("slug", v.slugify(e.target.value))

                    return field.onChange(e);
                }} {...props}/>
                <small>
                    <div>Slug: <strong>{form.values.slug}</strong></div>
                </small>
            </Fragment>}
            name="title"
            key="title"
        />
    </Col>
    <Col xs={12}>
        <label>Description</label>
        <Field
            label={<FormattedMessage
                id="APPLICATION.CONTENT_CATEGORY.DESCRIPTION"
            />}
            component="textarea"
            name="description"
            key="description"
            className="form-control"
        />
    </Col>
    <Col xs={12}>
        <Field name="cover">
            {
                ({field, form}) => {
                    return <ImageHolder
                        placeholder="PLACEHOLDER.ADD_COVER"
                        imageSrc={field?.value?.src}
                                        handleImage={val => form.setFieldValue(field.name, val)}/>;
                }
            }
        </Field>
    </Col>
</Row>


