import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {login} from "../_redux/authCrud";
import {forApiMock} from "../../../../_the_law/_helpers/forApiMock";
import {map} from 'lodash';
import {useHistory} from "react-router-dom";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    email: "",
    password: "",
};

function Login(props) {
    const {intl, isReLogin = false} = props;
    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID"
            }, {
                name: 'email'
            }))
            .min(3, intl.formatMessage({
                id: "AUTH.VALIDATION.MIN_LENGTH_FIELD"
            }, {
                min: 3
            }))
            .max(50, intl.formatMessage({
                id: "AUTH.VALIDATION.MAX_LENGTH_FIELD"
            }, {
                max: 50
            }))
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        password: Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            setTimeout(() => {
                login(values.email, values.password)
                    .then(({data: {token = null, accessToken = null}}) => {
                        disableLoading();
                        isReLogin ? props.re_login(forApiMock(accessToken, token)) : props.login(forApiMock(accessToken, token));
                    })
                    .catch((e) => {
                        disableLoading();
                        setSubmitting(false);
                        setStatus(
                            map(e?.response?.data?.errors, el => <p>{el[0]}</p>)
                        );
                    });
            }, 1000);
        },
    });

    const history = useHistory();

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE"/>
                </h3>
                <p className="text-muted font-weight-bold">
                    <FormattedMessage id="AUTH.LOGIN.SUBTITLE"/>
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {formik.status ? (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                ) : null}

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Email"
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                        autoComplete="username"
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                        autoComplete="current-password"
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span><FormattedMessage id="AUTH.GENERAL.SIGNIN_BUTTON"/></span>
                        {loading && <span className="ml-3 spinner spinner-white"/>}
                    </button>
                    <button
                        id="kt_login_signup"
                        type="button"
                        disabled={formik.isSubmitting}
                        onClick={event => {
                            event.preventDefault();
                            history.push("/auth/registration");
                        }}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span><FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON"/></span>
                        {loading && <span className="ml-3 spinner spinner-white"/>}
                    </button>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
