import React from "react";
import {Card as CardControl, CardBody, CardHeader, CardHeaderToolbar,} from "../../../_metronic/_partials/controls";
import {Grouping} from "./grouping/Grouping";
import {NewResourceButton} from "./partials/NewResourceButton";
import {useUIContext} from "./UIContext";

export function Card({filterProps, listCmp, filterCmp, cardActions = []}) {

    const {alias} = useUIContext()

    return (
        <CardControl>
            <CardHeader title={alias.listTitle}>
                <CardHeaderToolbar>
                    {cardActions}
                    <NewResourceButton/>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                {filterCmp && React.createElement(filterCmp, filterProps)}
                <Grouping/>
                {React.createElement(listCmp)}
            </CardBody>
        </CardControl>
    );
}
