import {FormattedMessage} from "react-intl";
import React from "react";


/**
 * Collezione degli elementi multilingua
 *
 *
 */

const FilterByCol = ({col, children}) => {
    return <FormattedMessage id="APPLICATION.GENERAL.FILTER_BY" values={{col}} children={children}/>
}

const CatMerc = ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.COLUMNS.PRODUCT_WH_TYPE"
                                                  children={children}/>
const SellChannel = ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.COLUMNS.SELL_CHANNEL"
                                                  children={children}/>
const ProductWhType = {
    Frozen: ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.PRODUCT_WH_TYPE.FROZEN"
                                              children={children}/>,
    Dry: ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.PRODUCT_WH_TYPE.DRY" children={children}/>,
    Perishable: ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.PRODUCT_WH_TYPE.PERISHABLE"
                                                  children={children}/>,
}

const ProductSellChannels = {
    B2c: ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.SELL_CHANNELS.B2C"
                                           children={children}/>,
    B2b: ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.SELL_CHANNELS.B2B"
                                           children={children}/>,
    A24: ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.SELL_CHANNELS.A24"
                                           children={children}/>,
    SlowFood: ({children}) => <FormattedMessage id="APPLICATION.PRODUCTS_PAGE.TABLE.SELL_CHANNELS.SLOW_FOOD"
                                           children={children}/>,
}

const ProductStatusApproval = {
    Approved: ({children}) => <FormattedMessage children={children} id="APPLICATION.PRODUCTS_PAGE.APPROVAL_STATUS.APPROVED"/>,
    ToApprove: ({children}) => <FormattedMessage children={children} id="APPLICATION.PRODUCTS_PAGE.APPROVAL_STATUS.TO_APPROVED"/>,
    Draft: ({children}) => <FormattedMessage children={children} id="APPLICATION.PRODUCTS_PAGE.APPROVAL_STATUS.DRAFT"/>
}

const Selectable = {
    All: ({children}) => <FormattedMessage id="APPLICATION.COMMON.SELECTABLE.ALL" children={children}/>
}


/**
 *
 * Utility per casi particolari
 *
 */

/**
 * Crea un tag <code>option</code> con messaggio tradotto per una select
 * @param value
 * @param children
 * @returns {React.DetailedReactHTMLElement<{children: (function(*): *)}, HTMLElement>}
 * @constructor
 */
export const AsOption = ({value = "", children}) => React.cloneElement(children, {
    children: (message) => <option value={value}>{message}</option>
})


export default {
    FilterByCol,
    CatMerc,
    SellChannel,
    Product: {
        WhType: ProductWhType,
        StatusApproval: ProductStatusApproval,
        SellChannel: ProductSellChannels,
    },
    Common: {
        Selectable
    },
    AsOption
}


