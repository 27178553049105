import {Col, Row} from "react-bootstrap";
import {Field, useFormikContext} from "formik";
import {FormattedMessage} from "react-intl";
import React from "react";
import {Input} from "../../../../../_metronic/_partials/controls";
import {SupervisorSelect} from "../partials/SupervisorSelect";
import {GAME_ROOMS_REDUX_SECTION_NAME} from "../../_constants";
import {MustHaveRules, RULES} from "../../../../../_the_law/_helpers/MustHaveRules";
import {GroupsSelection} from "../partials/GroupsSelection";

const ClassCodeField = () => {

    const {values} = useFormikContext();

    return (values.id && <Field
        label="Game Room Code"
        component={Input}
        name="game_codes[0].code"
        key="class_code"
        onChange={() => {
        }}
    />) || null;
}


export const fields = <Row className="inputs-rows mt-5">
    <Col xs={12}>
        <Field
            label={<FormattedMessage
                id="APPLICATION.COMMON.NAME"
            />}
            component={Input}
            name="name"
            key="name"
        />
    </Col>
    <Col xs={12}>
        <MustHaveRules rules={[RULES.ADMIN, RULES.SCHOOL]}>
            <SupervisorSelect
                name="supervisor_id"
                metaSection={GAME_ROOMS_REDUX_SECTION_NAME}
            />
        </MustHaveRules>
    </Col>
    <Col xs={12}>
        <GroupsSelection/>
    </Col>
    <Col xs={12}>
        <ClassCodeField/>
    </Col>
</Row>


